import React, { useState } from "react";
import article1 from "../assets/images/article1.jpg";
import article2 from "../assets/images/article2.jpg";
import article3 from "../assets/images/article3.png";
import article4 from "../assets/images/article4.jpg";
import date from "../assets/images/date.svg";
import time from "../assets/images/time.svg";
import logoAxe from "../assets/images/logo-axe.svg";

function Articles() {
    const [showResult, setShowResult] = useState(false);
    const onClick = () => setShowResult(!showResult);

    return (
        <div className="px-10 py-10 overflow-hidden bg-lightest-grey md:px-12 lg:px-28 xl:px-40  md:py-12 lg:py-16">
            <div className="justify-center w-full overflow-hidden md:justify-start">
                <a href="http://www.medium.com/@axehedge">
                    <h1 className="text-2xl font-bold md:text-4xl text-black-origin">
                        AxeHub
                    </h1>
                </a>
                <p className="w-full pt-3 pb-4 text-lg md:w-2/3 xxl:w-1/2 text-thin-grey md:pr-4 lg:pr-12">
                Investing in innovation starts with understanding it. We publish our original 
                research to enlighten investors on the impact of technologies and to seek 
                feedback on AxeHub's work.
                </p>
            </div>

            <div className="flex flex-wrap justify-center -mx-2 overflow-hidden md:justify-start sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2">
                <div className="flex justify-center w-full px-2 my-2 overflow-hidden lg:justify-end sm:my-2 sm:px-2 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 xl:my-2 xl:px-2">
                    <div className="max-w-sm mb-4 overflow-hidden rounded shadow-lg">
                        <div className="relative overflow-hidden">
                            <a href="https://medium.com/@sahry88/how-to-manage-a-stock-portfolio-2ffaa17ef178">
                                <img
                                    className="object-cover w-full h-52"
                                    src={article1}
                                    alt="Sunset in the mountains"
                                />
                                <div class="absolute top-0 left-0 px-4 pt-2 pb-4">
                                    <img
                                        className="w-32"
                                        src={logoAxe}
                                        alt="Sunset in the mountains"
                                    />
                                    <div class="mt-20 w-2/3 md:w-3/4 lg:w-2/3">
                                        <h4 class="mb-2 text-base lg:text-xl font-bold tracking-tight text-white">
                                            How to Manage a Stock Portfolio?
                                        </h4>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="px-2 divide-y divide-gray-300">
                            <div className="px-2 py-4">
                                <a href="https://medium.com/@sahry88/how-to-manage-a-stock-portfolio-2ffaa17ef178">
                                    <p className="text-xs ">
                                        Putting all your eggs in one basket has never been
                                        considered as one of the best strategies to consistently
                                        beat markets
                                    </p>
                                </a>
                            </div>

                            <div className="flex pb-2">
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2" src={date} alt="date"></img>
                                    02.02.2022
                                </span>
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2 lg:pl-2" src={time} alt="date"></img>3
                                    min read
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-2 px-2 w-full flex justify-center lg:justify-center overflow-hidden sm:my-2 sm:px-2 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                    <div className="max-w-sm mb-4 overflow-hidden rounded shadow-lg">
                        <div className="relative overflow-hidden">
                            <a href="https://www.publish0x.com/finance-and-markets/what-are-hedge-funds-a-beginners-guide-to-hedge-funds-xlzwnmx">
                                <img
                                    className="object-cover w-full h-52"
                                    src={article2}
                                    alt="Sunset in the mountains"
                                />
                                <div class="absolute top-0 left-0 px-4 pt-2 pb-4">
                                    <img
                                        className="w-32"
                                        src={logoAxe}
                                        alt="Sunset in the mountains"
                                    />
                                    <div class="mt-20">
                                        <h4 class="mb-2 text-base lg:text-xl font-bold tracking-tight text-white">
                                            What are Hedge Funds? - A Beginner's Guide to Hedge Funds
                                        </h4>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="px-2 divide-y divide-gray-300">
                            <div className="px-2 py-4">
                                <a href="https://www.publish0x.com/finance-and-markets/what-are-hedge-funds-a-beginners-guide-to-hedge-funds-xlzwnmx">
                                    <p className="text-xs ">
                                        Hedge Funds are forms of financial partnerships where
                                        private and professional investors create a capital pool
                                    </p>
                                </a>
                            </div>

                            <div className="flex pb-2">
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2" src={date} alt="date"></img>
                                    02.02.2022
                                </span>
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2 lg:pl-2" src={time} alt="date"></img>3
                                    min read
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-2 px-2 w-full flex justify-center lg:justify-start overflow-hidden sm:my-2 sm:px-2 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                    <div className="max-w-sm mb-4 overflow-hidden rounded shadow-lg">
                        <div className="relative overflow-hidden">
                            <a href="https://medium.com/@axehedge/ark-tesla-bitcoin-etfs-lessons-from-hedge-fund-titan-cathie-wood-cbcb13098c7b">
                                <img
                                    className="object-cover w-full h-52"
                                    src={article3}
                                    alt="Sunset in the mountains"
                                />
                                <div class="absolute top-0 left-0 px-4 pt-2 pb-4">
                                    <img
                                        className="w-32"
                                        src={logoAxe}
                                        alt="Sunset in the mountains"
                                    />
                                    <div class="mt-20">
                                        <h4 class="mb-2 text-base lg:text-xl font-bold tracking-tight text-white">
                                            ARK, Tesla & Bitcoin ETFs. Lessons from Hedge Fund Titan
                                            Cathie Wood
                                        </h4>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="px-2 divide-y divide-gray-300">
                            <div className="px-2 py-4">
                                <a href="https://medium.com/@axehedge/ark-tesla-bitcoin-etfs-lessons-from-hedge-fund-titan-cathie-wood-cbcb13098c7b">
                                    <p className="text-xs ">
                                        The strategy of investing in high-growth, high-reward and,
                                        high-risk disruptive technology stocks has put Cathie Wood's
                                    </p>
                                </a>
                            </div>

                            <div className="flex pb-2">
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2" src={date} alt="date"></img>
                                    02.02.2022
                                </span>
                                <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                    <img className="pr-2 lg:pl-2" src={time} alt="date"></img>3
                                    min read
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {showResult ? (
                    <div class="my-2 px-2 w-full flex justify-center xxl:justify-start overflow-hidden sm:my-2 sm:px-2 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                        <div className="max-w-sm mb-4 overflow-hidden rounded shadow-lg">
                            <div className="relative overflow-hidden">
                                <a href="https://medium.com/@sahry88/why-hedge-funds-are-attractive-alternative-investment-for-novice-investors-dd0331aca880">
                                    <img
                                        className="object-cover w-full h-52"
                                        src={article4}
                                        alt="Sunset in the mountains"
                                    />
                                    <div class="absolute top-0 left-0 px-4 pt-2 pb-4">
                                        <img
                                            className="w-32"
                                            src={logoAxe}
                                            alt="Sunset in the mountains"
                                        />
                                        <div class="mt-20 lg:mt-16 xl:mt-20">
                                            <h4 class="mb-2 text-base lg:text-xl font-bold tracking-tight text-white">
                                                Why Hedge Funds are Attractive Alternative Investment
                                                for Novice Investors?
                                            </h4>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="px-2 divide-y divide-gray-300">
                                <div className="px-2 py-4">
                                    <a href="https://medium.com/@sahry88/why-hedge-funds-are-attractive-alternative-investment-for-novice-investors-dd0331aca880">
                                        <p className="text-xs ">
                                            Investors who wish to earn a decent income from global
                                            stock and financial markets but lack the time
                                        </p>
                                    </a>
                                </div>

                                <div className="flex pb-2">
                                    <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                        <img className="pr-2" src={date} alt="date"></img>
                                        02.02.2022
                                    </span>
                                    <span className="flex py-1 mb-2 mr-2 text-sm font-normal bg-gray-200 text-black-origin">
                                        <img className="pr-2 lg:pl-2" src={time} alt="date"></img>3
                                        min read
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>

            <div className="flex justify-center w-full h-20 pt-8 xl:1/2">
                <button
                    onClick={onClick}
                    className="w-full px-4 py-2 font-bold bg-transparent border rounded md:w-1/2 xl:w-1/2 xxl:w-1/3 text-thin-grey border-thin-grey"
                >
                    {showResult ? "Show less" : "Show more"}
                </button>
            </div>
        </div>
    );
}

export default Articles;
