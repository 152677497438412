


const HeroLeftSide = () => {
  return (
    <div className="overflow-hidden md:mb-20">
      <h1 className="px-4 md:px-16 lg:px-0 lg:pl-36 lg:pt-2 xl:w-5/6 text-3xl xl:text-4xl leading-9 font-bold tracking-wider text-black poppins-font">
        Investment solutions for everyone
      </h1>
      <p className="px-4 md:pl-16 lg:pl-36 lg:pt-2 font-normal text-base mt-2 lg:text-xl text-thin-grey poppins-font">
        Make better investment decisions
      </p>

    
      
    </div>
  );
};

export default HeroLeftSide;
