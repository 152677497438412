import React from "react";
import Email from "../Shared/Email";
import HeroLeftSide from "./HeroLeftSide";
import HeroRightSide from "./HeroRightSide";

import android from "../../assets/images/android.svg";
import ios from "../../assets/images/ios.svg";

const Hero = () => {
	return (
		<div className="flex pt-6 flex-wrap overflow-hidden bg-white pb-12 xl:pb-0">
			<div className="w-full lg:mt-16 lg:w-1/2 ">
				<HeroLeftSide />
				<div className="w-full flex mt-5 px-3 sm:px-16 lg:px-0 lg:pl-36 flex-col">
					<Email />

					<div className="w-full mt-10 mb-28 flex content-center justify-center md:justify-start">
						<a href="https://play.google.com/store/apps/details?id=com.axehedge">
							<div className="sm:mr-2 md:mr-6">
								<img src={android} alt="" />
							</div>
						</a>

						<a href="https://apps.apple.com/pk/app/axehedge/id1614618600">
							<div>
								<img src={ios} alt="" />
							</div>
						</a>
					</div>
				</div>
			</div>

			<HeroRightSide />
		</div>
	);
};

export default Hero;
