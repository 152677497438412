import React, { useEffect, useState } from "react";
import { signin, signup } from "../global";
import whiteaxehedge from "../assets/images/whiteaxehedge.svg";
import EmailSection from "../components/OldFooter/Footer/EmailSection";
import SocialSection from "../components/Footer/SocialsSection";
import FaqSearch from "../components/Shared/FaqSearch";
import { getAllFaq } from "../API/getFAQs";
import { useHistory } from "react-router";
function FaqList() {
  const history = useHistory();
  const [dataFaq, setDataFaq] = useState([]);
  const handleClick = (id) => {
    history.push(`/faqs/${id}`);
  };
  const getFaqs = async () => {
    try {
      const result = await getAllFaq();
      if (result.status === 200) {
        if (result.data.is_success) {
          setDataFaq(result.data.data);
        } else {
        }
      } else {
      }
    } catch (error) { }
  };
  useEffect(() => {
    getFaqs();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-theme-color h-20-vh">
        <div className="flex px-4 md:px-16 lg:px-36 pt-4 place-content-between overflow-hidden ">
          <img src={whiteaxehedge} alt="" />
          <div className="place-content-between">
            {/* <a href={signin}>
              <button className="h-12 w-24 hidden md:inline-block lg:w-32 text-base font-semibold justify-between text-action-blue   hover:text-web-yellow">
                Login
              </button>
            </a>
            <a href={signup}>
              <button className="h-12 w-24 lg:w-32 text-base text-action-blue font-semibold justify-between bg-white hover:bg-web-yellow b-r-5">
                Register
              </button>
            </a> */}
          </div>
        </div>

        <div className="flex px-4 md:px-16 lg:px-36 custom-p-134 place-content-between  ">
          <FaqSearch setDataFaq={setDataFaq} dataFaq={dataFaq} />
        </div>
      </div>
      <div className="py-6 block px-4 md:px-16 lg:px-36  place-content-between space-y-4 ">
        <div className=" grid height-faq sm:grid-rows-4 md:grid-rows-3 md:grid-flow-col gap-4">
          {dataFaq.map((value) => {
            return (
              <div
                className="flex justify-center div-outline-cls flex-wrap content-center text-blue"
                onClick={() => {
                  handleClick(value._id);
                }}
              >
                <h6 className="px-8 text-blue text-center">{value.question} </h6>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-action-blue lg:px-36 divide-y-2 divide-light-color overflow-hidden">
        <EmailSection />
        <SocialSection />
        <div className=" py-6 text-light-grey text-xs md:text-base font-thin px-4 flex flex-row justify-between">
          <p className='text-white hidden md:block'>Copyright © 2022 AxeHedge. All rights reserved</p>

          <div className="flex flex-row gap-x-1">
            <p className="links-footer pr-4" onClick={() => { history.push('/faqs') }}>FAQs</p>
            <p className="links-footer pr-4" onClick={() => { history.push('/general-disclosure') }}>General Disclousre</p>
            <p className="links-footer pr-4" onClick={() => { history.push('/terms-and-conditions') }}>Terms & Conditions</p>
            <p className="links-footer" onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</p>
          </div>
        </div>
        <div>
          <p className='text-white block md:hidden m-3'>Copyright © 2022 AxeHedge. All rights reserved</p>
        </div>
      </div>
    </>
  );
}

export default FaqList;
