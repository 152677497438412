import Api from "./index";
export const getAllFaq = async () => {
  try {
    return await Api.get("/api/v1/public/faqs");
  } catch {
    return {
      status: 404,
    };
  }
};

export const getFaq = async (value) => {
  try {
    return await Api.get(`/api/v1/public/faq/${value}`);
  } catch {
    return {
      status: 404,
    };
  }
};
