import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { validEmail } from "../../Utils/Validations.js";
import axios from "axios";

import { successMessage } from "../../global";

const OldEmail = () => {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Please enter email address.");
      return;
    } else if (validEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Please enter valid email address.");
      return;
    }
    setEmailSuccess(true);

    axios
      .post(
        "https://sheet.best/api/sheets/f8e25cb5-7aa4-445b-81ec-5ce795aff1c5",
        { Email: email }
      )
      .then((response) => {
        console.log("Response:", response);
      })
      .catch((error) => console.log("Error:", error.message));
  };

  return (
    <div className="relative w-full overflow-hidden">
      {!emailSuccess ? (
        <div>
          <div className="absolute top-4 left-3">
            {" "}
            <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>{" "}
          </div>{" "}
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="h-12 w-full  pl-4 md:pl-10 lg:pl-6 pr-36 md:pr-52 rounded-3xl z-0 border-2 text-sm border-light-color focus:shadow focus:outline-none"
            placeholder="Enter your email"
          />
          <div className="absolute top-0 right-0">
            {" "}
            <button
              onClick={(event) => handleSubmit(event)}
              className="h-12 w-36 md:h-12 md:w-52 lg:w-42 text-sm font-bold justify-between  bg-web-yellow rounded-3xl bg-red-500 hover:bg-red-600 place-content-between"
            >
              <span>Get Early access</span>{" "}
              <span className="md:pl-5">
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </button>{" "}
          </div>
          <span>
            {emailError && (
              <p className="text-danger-color text-sm pl-2">{emailErrorText}</p>
            )}
          </span>{" "}
        </div>
      ) : (
        <div className="h-12 w-full rounded-3xl z-0 bg-light-green ">
          <p className="text-sm md:text-lg flex items-center justify-center pt-2.5">
            {successMessage}
          </p>
        </div>
      )}
    </div>
  );
};

export default OldEmail;
