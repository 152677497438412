import React from "react";
import stock from "../assets/images/stock.svg";
import protfolio from "../assets/images/portfolio.svg";
import AI from "../assets/images/AI.svg";
import blackCard from "../assets/images/black-card.svg";
import mission from "../assets/images/mission.svg";
const WhatWeDO = () => {
  return (
    <>
      <div className="py-20 overflow-hidden bg-black-dark">
        <div className="w-full text-center text-white">
          <h1 className="my-2 text-3xl font-bold ">
            A systematic approach towards investing
          </h1>

          <p className="mb-4 text-base">
            We think that investing should be made simple, easy to understand
            and technology driven.
          </p>
        </div>

        <div class="flex flex-wrap pt-6 -mx-2 overflow-hidden sm:-mx-8 md:-mx-2 lg:-mx-2 xl:-mx-2 ">
          <div class="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-2 md:px-2 lg:my-2 lg:px-2 lg:w-1/2 xl:my-2 xl:px-6 xl:w-1/2 flex justify-center lg:justify-end">
            <img className="" src={stock} alt="stock" />
          </div>

          <div class="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-2 md:px-2  lg:my-2 lg:px-2 lg:w-1/2 xl:my-2 xl:px-6 xl:w-1/2 flex justify-center lg:justify-start">
            <img className="" src={protfolio} alt="stock" />
          </div>
        </div>

        <div class="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-1 xl:-mx-1 lg:pt-4">
          <div class="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-full lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:px-1 xl:w-1/3 flex justify-center lg:justify-end">
            <img className="" src={blackCard} alt="stock" />
          </div>

          <div className="flex justify-center w-full px-2 my-2 overflow-hidden sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-full lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:px-1 xl:w-1/3 lg:justify-center">
            <div className="relative overflow-hidden">
              <img className="" src={AI} alt="stock" />

              <div class="absolute top-4 left-6 text-white">
                <p className="text-sm font-semibold uppercase">
                  We are machine + human
                </p>

              </div>
            </div>
          </div>

          <div className="flex justify-center w-full px-2 my-2 overflow-hidden sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-full lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:w-1/3 lg:justify-start">
            <a href="http://www.medium.com/@axehedge">
              <div className="relative overflow-hidden ">
                <img className="black-shadow" src={mission} alt="stock" />

                <div class="absolute top-4 left-6 text-white">
                  <p className="text-sm font-semibold uppercase">
                    We are education based
                  </p>

                </div>

                <div class="absolute bottom-20 lg:bottom-20 xl:bottom-12 left-4 text-white justify-center ">
                  <p className="px-10 text-sm font-semibold text-center md:text-lg lg:px-6">
                    Our mission is to guide and educate users through
                    the same methodical and systematic approaches applied at investment firms
                  </p>

                  <p className="w-full px-10 pt-8 text-xs text-center">
                    Click here to access our research and education portal, AxeHub
                  </p>
                </div>

              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDO;
