import React from 'react'
import { signin, signup } from "../global";
import whiteaxehedge from "../assets/images/whiteaxehedge.svg";
import EmailSection from '../components/OldFooter/Footer/EmailSection';
import SocialSection from '../components/Footer/SocialsSection';
import { useHistory } from 'react-router';

function PrivacyPolicy() {
    const history = useHistory()
    return (
        <>
            <div className="main-theme-color h-20-vh">
                <div className="flex px-4 md:px-16 lg:px-36 pt-4 place-content-between overflow-hidden ">
                    <img src={whiteaxehedge} alt="" />
                    <div className="place-content-between">
                        {/* <a href={signin}>
                            <button className="h-12 w-24 hidden md:inline-block lg:w-32 text-base font-semibold justify-between text-action-blue   hover:text-web-yellow">
                                Login
                            </button>
                        </a>
                        <a href={signup}>
                            <button className="h-12 w-24 lg:w-32 text-base text-action-blue font-semibold justify-between bg-white hover:bg-web-yellow b-r-5">
                                Register
                            </button>
                        </a> */}
                    </div>
                </div>

                <div className="flex px-4 md:px-16 lg:px-36 custom-p-134 place-content-between  ">
                    <h1 className="text-white text-5xl poppins-font "> {"Privacy Policy"}</h1>
                </div>
            </div>

            <div className="py-6 block px-4 md:px-16 lg:px-36  place-content-between space-y-4 secondary-text">

                <p> </p>
                <h1><strong>INTRODUCTION TO THE PRIVACY POLICY</strong></h1>
                <p> </p>
                <p>This policy ("Privacy Policy") describes how Axehedge Sdn. Bhd. (Company No.  202101025223) ("Axehedge", “Company”, “we”, “us”, “our”), a company incorporated and registered in Malaysia manages Personal Data which is in our possession or under our control. "Personal Data", is defined in the Personal Data Protection Act 2010 of Malaysia (“PDPA”), and currently refers to any information in respect of commercial transactions, that relates directly or indirectly to an individual, who is identified or identifiable from that information or from that and other information which is in our possession and includes any sensitive personal data and expression of opinion about the individual.</p>
                <p> </p>
                <p>This privacy policy applies to your use of all the mobile applications, products, software, features, services, websites, publications, email subscription and the content thereof, other services, and your relationship with us (collectively, the “Service”) owned or controlled by Axehedge, or its affiliated companies.</p>
                <p> </p>
                <p>By using our services pursuant to your agreement(s) with us ("Services"), accessing the online platform operated by us (which is accessible through our website at www.axehedge.com or through our mobile application) ("Platform"), websites or mobile applications, or otherwise providing information to or communicating with us, you are taken to have agreed to our collection, use, processing and disclosure of your Personal Data in accordance with this Privacy Policy.</p>
                <p> </p>
                <p>This Privacy Policy does not supersede or replace any other consents you may have previously or separately provided to us in respect of your Personal Data, and your consent to this Privacy Policy is in addition to any other rights which we may have at law to collect, use, process or disclose your Personal Data.</p>
                <p> </p>
                <p>The words "we", "us", "our" or any of their derivatives refer to Axehedge and its successors and any novatee, assignee, transferee or purchaser of Axehedge's rights and/or obligations hereunder and any reference to Axehedge includes a reference to such successor, novatee, assignee, transferee or purchaser. The words "you", "your", "yours" or any of their derivatives refer to the person using our Services, accessing our Platform, website or mobile applications, or otherwise providing information to or communicating with us and shall include, as the context may require, personal representatives (as the case may be).</p>
                <p> </p>
                <p>This Privacy Policy shall be governed by, and construed in accordance with, the laws of Malaysia. Without prejudice to your rights under any applicable laws, any dispute arising out of or in connection with this Privacy Policy and/or the documents referred to herein, including any question regarding their existence, validity or termination, shall be referred to and finally resolved by the Courts of Malaysia and both you and we hereby unconditionally and irrevocably submit to the exclusive jurisdiction of the Courts of Malaysia.</p>
                <p> </p>
                <p>We hope you take some time to read through it carefully, as it is important. If there are any</p>
                <p>terms in this Policy that you do not agree with, please discontinue use of our Service, and we have the right not to provide you with access to our Service.</p>
                <p> </p>
                <h1><strong>1. PERSONAL DATA THAT WE COLLECT</strong></h1>
                <p><strong> </strong></p>
                <p>We collect personal information that you voluntarily provide to us when registering for our Service, expressing an interest in obtaining information about us or our products and services.</p>
                <p> </p>
                <p>The Personal Data that we collect or may collect include:</p>
                <p> </p>
                <ol className="list-disc pl-4"><li>  Personal contact data including name, telephone number, email address, residential address and correspondence address;</li>
                    <li>  Specimen signature(s);</li>
                    <li>  Occupation, education and income levels;</li>
                    <li>  Identification card or passport number, date of birth, place of birth and other information for the verification of identity;</li>
                    <li>  Financial and banking information (e.g. information on net assets, income, expenses, credit history, bank account and banking transactions, securities trading account);</li>
                    <li>  Images and voice recordings of our conversations with you;</li>
                    <li>  Tax and insurance information;</li>
                    <li>  Information about your risk profile, investments, investment objectives, knowledge and experience and/or business interests and assets;</li>
                    <li>  Personal opinions made known to us (e.g. your feedback or responses to any surveys);</li>
                    <li>  Browsing history, patterns or other unique information;</li>
                    <li>  Your internet protocol address and information associated with such address;</li>
                    <li>  Any other personal data reasonably required in order for us to provide the services requested by you; and</li>
                    <li>  Any other personal data permitted by or required to comply with any applicable local or foreign laws, rules, acts, regulations, subsidiary legislation notices, notifications, circulars, licence conditions, directions, requests, requirements, guidelines, directives, codes, information papers, practice notes, demands, guidance and/or decisions of any national, state or local government, any agency, exchange, regulatory or self-regulatory body, law enforcement body, court, central bank or tax revenue authority or any other authority whether in Malaysia or elsewhere, whether having the force of law or not (including any intergovernmental agreement between the governments or regulatory authorities of two or more jurisdictions or otherwise), as may be amended from time to time ("Applicable Laws") and our internal control and compliance policies.</li>
                    <li>  Written communication you have with us such as when you send us an email or when there are documents provided to us prior to and during the course of our business relationship.</li></ol>
                <p> </p>
                <p>We get information about you in a range of ways:</p>
                <p> </p>
                <p><strong>Information You Give Us.</strong> We collect information you directly give us on the Service in a variety of ways, including but not limited to when you register for an account; purchase a product or service; participate in a sweepstakes, contest, rewards program or other promotion; use the Service to upload, share, or communicate any content or information; publicly post information via links to social media networks, forums, blogs, message boards, chat rooms or similar functionality; and when you request customer support or provide feedback and or survey responses.</p>
                <p> </p>
                <p><strong>Information We Get From Others.</strong> We may get information about you from other sources, such as Facebook, Twitter, Instagram or other social-media properties. Other users of the Service, including but not limited to marketplace users. We may add this to information we get from this Service.</p>
                <p> </p>
                <p><strong>Information Automatically Collected.</strong></p>
                <p><strong>●</strong>        Traffic and usage information generated from your visit to the Service, including traffic data, logs, pages you visit, your device’s unique device identifier, mobile network, browser information, operating system, IP address, cookie information, the type of device that you use to access the Service, and any other communication data arising from your access and use of the Service;</p>
                <p><strong>●</strong>        In connection with your visit or use of the Service, we may automatically collect information about our users, such as the numbers and frequency of users and their characteristics and information about similar groups of users, certain age groups or users that live in a particular geographical area. This data is only used in the aggregate as a statistical measure and not in a manner that would identify you personally. Aggregate information generally is collected through the use of cookies and beacons;</p>
                <p><strong>●</strong>        Information from public databases and third parties;</p>
                <p><strong>●</strong>        When you access or use the Service, or any portion thereof, we may collect physical location information that is sufficiently precise to allow us to locate a specific person or device (“Location Information”). We only collect Location Information with your permission (e.g., such as when you agree to provide it to us after you initially access or use the Service). We may use Location Information and provide it to third parties to provide you with content and services that are relevant to a particular location, such as advertising, search results, and directions; and</p>
                <p><strong>●</strong>        The Service may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts and address book information.</p>
                <p> </p>
                <p>If you do not want us to collect this information do not download the Service or delete it from your device. We also may use these technologies to collect information about your activities over time and across third-party websites, apps or other online services (behavioral tracking).</p>
                <p> </p>
                <p><strong>To Summarise: We collect personal information that you provide to us such as name, address, contact information, telephone number, occupation and security data.</strong></p>
                <p> </p>
                <h2><strong>2. USE OF PERSONAL DATA</strong></h2>
                <p> </p>
                <p>We may use your Personal Data for our business purposes, including the provision and continuing operation of the Platform and the Services provided to you. This includes, the following purposes ("Purposes"):</p>
                <p> </p>
                <p><strong>●</strong>        To provide, operate, maintain, improve, and promote the Service, and any other information, products or services that you request from us.</p>
                <p><strong>●</strong>        To enable you to access and use the Service, including uploading, downloading, collaborating and sharing content, photos, images, videos and information, with other users or third parties.</p>
                <p><strong>●</strong>        To process, verify, and carry out transactions, and send you related information, including purchase confirmations and invoices, to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing, collection and enforcement, and to build a user database.</p>
                <p><strong>●</strong>        To send transactional messages, including responding to your comments, questions, and requests; providing customer service and support; sending you technical notices, updates, security alerts, and support and administrative messages; and, when we have location information, we use it to tailor our Service for you and others.</p>
                <p><strong>●</strong>        To process and deliver contest entries and rewards.</p>
                <p><strong>●</strong>        To send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</p>
                <p><strong>●</strong>        To communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.</p>
                <p><strong>●</strong>        To monitor and analyze trends, usage, and activities in connection with the Service and for marketing, advertising, administrative, analytical, research, optimization, and other purposes. For example, we may use statistical analyses of usage data in order to measure interest in the various areas of the Service, for development purposes and to inform advertisers and partners in aggregate and anonymous terms about consumers who click on their advertising or other messages. IP addresses are also used for systems administration and troubleshooting purposes.</p>
                <p><strong>●</strong>        To link or combine user information with other Personal Data.</p>
                <p><strong>●</strong>        To protect, investigate, and deter against negligence, fraud, theft and other illegal activities.</p>
                <p> </p>
                <p><strong>To Summarise: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</strong></p>
                <p> </p>
                <h1><strong>3. DISCLOSURE AND SHARING OF YOUR PERSONAL DATA</strong></h1>
                <p> </p>
                <p>We may from time to time disclose and share your Personal Data to our directors, officers, employees, representatives, agents or delegates or any third parties, whether located in Malaysia or otherwise, to carry out the Purposes.</p>
                <p> </p>
                <p>We may process or share data based on the following legal basis:</p>
                <p> </p>
                <p><strong>Consent. </strong>We may process your data if you have given us specific consent to use your personal information for a specific purpose.</p>
                <p><strong>Legitimate Interests. </strong>We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>
                <p><strong>Performance of a Contract.</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>
                <p><strong>Legal Obligations.</strong> We may disclose your information where we are legally required to do so in order to comply with applicable laws and regulations, governmental requests, judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
                <p><strong>Vital Interests. </strong>We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
                <p>We may need to process your data or share your personal information in the following situations:</p>
                <p><strong>Vendors, Consultants and Other Third Party Service Providers.</strong> We may share your
                    data with third party vendors, service providers, contractors or agents who perform
                    services for us or on our behalf and require access to such information to do that work.
                    We may allow selected third parties to use tracking technology on the Service, which
                    will enable them to collect data about how you interact with the Service over time. This
                    information may be used to, among other things, analyze and track data, determine the
                    popularity of certain content and better understanding online activity. Unless described in
                    this Policy, we do not share, sell, rent or trade any of your information with third parties
                    for their promotional purposes. These third parties may have their own privacy policy in
                    place, you are advised to be familiar with their privacy policies.
                </p>
                <p><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or</p>
                <p>during negotiations of, any merger, sale of company assets, financing, or acquisition of</p>
                <p>all or a portion of our business to another company.</p>
                <p> </p>
                <p><strong>Business Partners. </strong>We may share your information with our business partners to offer you certain products, services or promotions.</p>
                <p> </p>
                <p>We only share and disclose your information to departments within Axehedge, our affiliates, and the following third parties. We have categorized each party so that you may easily understand the purpose of our data collection and processing practices.</p>
                <p> </p>
                <p>We may also disclose your information to:</p>
                <p><strong>●</strong>        auditors, lawyers, advisors, accountants, agents, or other representatives who are under the duty of confidentiality with us;</p>
                <p><strong>●</strong>        any relevant authorities, governmental or otherwise;</p>
                <p><strong>●</strong>        any fraud prevention agencies;</p>
                <p><strong>●</strong>        any central credit reference information systems;</p>
                <p><strong>●</strong>        any other authorized person under the laws of the jurisdiction which are applicable to us</p>
                <p> </p>
                <p><strong>To Summarise: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations</strong></p>
                <p><strong> </strong></p>
                <h4><strong>4. PRIVACY RIGHTS AND RECTIFICATION OF DATA</strong></h4>
                <p> </p>
                <p><strong>Account Information.</strong> If you would at any time like to review or change the information in your account or terminate your account, you can log into the Service, navigate to Profile and update or revise your user account and other information related to your account or to delete your data. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
                <p> </p>
                <p><strong>Opting out of email marketing. </strong>You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we will still need to send you transactional emails that are necessary for the administration and use of your account (eg: order confirmations).</p>
                <p> </p>
                <p><strong>Violation of principles. </strong>If you have reason to believe that we have violated the principles set out in this Policy, we urge you to contact us. You can email us at support@heygotrade.com with your concerns. Upon receipt of such an email, you will receive a response from us within 21 days. If we find that the complaint is justified, we will resolve the matter.</p>
                <p> </p>
                <p><strong>To Summarise: You may review, change, or terminate your account at any time.</strong></p>
                <p> </p>
                <h1><strong>5. DATA RETENTION</strong></h1>
                <p> </p>
                <p>We will only retain your Personal Data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for Personal Data, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements. In some circumstances you can ask us to delete your data. In some circumstances we may anonymize your Personal Data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
                <p> </p>
                <p><strong>To Summarise: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</strong></p>
                <p> </p>
                <h1><strong>{"6. DATA PROTECTION & SECURITY"}</strong></h1>
                <p> </p>
                <p>We agree that we:</p>
                <p><strong>●</strong>        will only process your information in accordance with the instructions given by you and in accordance with the PDPA;</p>
                <p><strong>●</strong>        will take appropriate measures in terms of management and technical services to protect your information from</p>
                <p><strong>○</strong>        (i) any processing or usage which is not authorized or</p>
                <p><strong>○</strong>        (ii) the loss or destruction caused by our negligence;</p>
                <p><strong>●</strong>        will not keep your information longer than is reasonably necessary for the fulfillment of the lawful purposes which are permitted by law.</p>
                <p> </p>
                <p><strong>Security Measures.</strong> We have implemented physical, electronic, and managerial procedures designed to secure your personal information from accidental loss, unauthorized access, to maintain data security, and to correctly use the information we collect online. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of the Service, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
                <p> </p>
                <p><strong>No Security Guarantees. </strong>Although we do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted through the Service. Any transmission of Personal Data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide. Although we take appropriate measures to safeguard against unauthorized disclosures of Personal Data, we cannot assure you that Personal Data will never be disclosed, altered or destroyed in a manner that is inconsistent with this Privacy Policy.</p>
                <p> </p>
                <p><strong>To Summarise: We aim to protect your personal information through a system of organizational and technical security measures.</strong></p>
                <p> </p>
                <h1><strong>7. TRACKING TECHNOLOGIES</strong></h1>
                <p> </p>
                <p>We may use one or more of the following tracking technologies, and similar future tracking technologies, to automatically collect information: cookies, local shared objects, web beacons, unique telephone numbers, general log information and referral information from third-party services.</p>
                <p> </p>
                <p><strong>Cookies.</strong> We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on the Service. This type of information is collected to make the Service more useful to you and to tailor the experience with us to meet your special interests and needs.</p>
                <p> </p>
                <p><strong>Web Beacons.</strong> Web Beacons, also known as Internet tags, pixel tags, single-pixel GIFS or clear GIFs, link web pages to web servers and their cookies. Web Beacons can be embedded in web pages, videos, or emails, to collect certain types of information from your browser, check whether you have viewed a particular web page or email message, and determine, among other things, the time and date on which you viewed the content, the IP address of your computer, and the URL of the web page from which the content was viewed.</p>
                <p> </p>
                <p><strong>Local Shared Objects.</strong> Local Shared Objects, sometimes known as Flash cookies, may be used to store your preferences or display content based upon what you have viewed on various websites to personalize your visit.</p>
                <p> </p>
                <p><strong>“Ad IDs” and Other In-App Tracking Methods.</strong> There are a variety of tracking technologies that may be included in mobile applications, and these are not browser-based like cookies and cannot be controlled by browser settings. Some use device identifiers, or other identifiers such as “Ad IDs” to associate app user activity to a particular app.</p>
                <p> </p>
                <p><strong>Google Analytics.</strong> We use Google Analytics (“Google Analytics”), a web analytics service provided by Google Inc. (“Google”). Google Analytics uses “cookies.” The information generated by the cookies about your use of the Service (including your IP address) will be transmitted to and stored by Google. Google will use this information for the purpose of evaluating your use of the Service, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you do this, you may not be able to use the full functionality of the Service. By using the Service, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
                <p> </p>
                <p><strong>To Summarise: We may collect information, your Personal Data, through cookies on our Platform, website and mobile applications. We use analytics programs such as Google Analytics to manage and improve our websites, mobile applications, the Platform and/or our Services. We may with the help of Google Analytics use your browsing behaviour and prior usage patterns together with any other Personal Data previously provided by you in accordance with this Privacy Policy.</strong></p>
                <p> </p>
                <h1><strong>8. USERS FROM OTHER JURISDICTIONS</strong></h1>
                <p> </p>
                <p>By using the Service, you acknowledge that you accept the practices and policies outlined in this Privacy Policy and consent to having your data transferred to and processed on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. If you do not accept this Privacy Policy, please do not use the Service. The Service is controlled and operated by the Company from Malaysia. If you are not a resident of Malaysia or you are located outside of Malaysia and choose to use the Service or provide information to us, please note that we will transfer the information, including Personal Data, to Malaysia and process it there. Your acceptance of this Privacy Policy, followed by your submission of such information represents your agreement and consent to that transfer. We do not represent or warrant that the Service, or any portion thereof, are appropriate or available for use in any particular jurisdiction. Those who choose to access the Service do so on their own initiative and at their own risk, and are responsible for complying with all local laws, rules and regulations. You also are subject to Malaysia export controls in connection with your use of the Service and are responsible for any violations of such controls, including, without limitation, any Malaysia embargoes or other federal rules and regulations restricting exports.</p>
                <p> </p>
                <p>We may limit the availability of the Service, in whole or in part, to any person, geographic area or jurisdiction that we choose, at any time and in our sole discretion.</p>
                <p> </p>
                <h1><strong>9. THIRD PARTY SITES</strong></h1>
                <p> </p>
                <p>Our web sites may contain links to other web sites which are not maintained by us. This Privacy Policy only applies to our websites, mobile applications, the Platform and/or our Services. When visiting these third party websites, you should read their privacy policies which will apply to your use of the web sites.</p>
                <p> </p>
                <p><strong>To Summarise: This Privacy Policy does not apply to third party web sites which may be linked to our web sites.</strong></p>
                <p> </p>
                <h1><strong>10. SUPPLY OF DATA</strong></h1>
                <p> </p>
                <p>Except as otherwise provided in this Privacy Policy, we only collect the Personal Data that you provide to us. If you would prefer that we do not collect Personal Data from you, please do not provide us with such information, use our Services, operate any account maintained with us, or access our Platform, websites or mobile applications.</p>
                <p> </p>
                <p>By using our Services, visiting our Platform, websites or mobile applications or otherwise, providing information to, or communicating with us you shall be deemed to have read and provided your consent to our collection, use, processing and disclosure of your Personal Data or Personal Data relating to other persons in the manner as described in this Privacy Policy. You may withdraw your consent to our collection, use, processing and disclosure of the Personal Data for any of the above Purposes, at any time by contacting us via the contact details provided below in Paragraph 13. If we do not receive any notification from you concerning our collection, use, processing and disclosure of the Personal Data, we shall proceed to continue to process the same for the Purposes as set out above, on the basis that you have consented to our current as well as all our previous collection, use, processing and disclosure of the Personal Data in accordance with this Privacy Policy.</p>
                <p> </p>
                <p>Please note that we may not be able to provide you with our Services if you do not provide us with your consent or withdraw your consent to the collection, use, processing and disclosure of the Personal Data for any of the Purposes.</p>
                <p> </p>
                <p>Where you provide us with the Personal Data of other individuals, we shall assume, without independent verification, that you have obtained such individual's consent to our collection, use, processing and disclosure of their Personal Data in accordance with this Privacy Policy.</p>
                <p> </p>
                <p><strong>To Summarise: You consent to us collecting, using, processing and disclosing your Personal Data in the manner as described in this Privacy Policy. You may withdraw your consent at any time by contacting us. In the event that you withdraw your consent, we may not be able to provide you with our Services. If you provide us with the Personal Data of other individuals, you will ensure that the individual has given their consent to our collection, use, processing and disclosure of their Personal Data in accordance with this Privacy Policy.</strong></p>
                <h1><strong>11. POLICY UPDATES</strong></h1>
                <p> </p>
                <p>Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page, for which you will be notified. If you continue to use our Services, access our Platform, websites or mobile applications, and/or otherwise provide information to or communicate with us, you are deemed to have agreed to such changes without reservation.</p>
                <p> </p>
                <p><strong>To Summarise: Yes, we will update this policy as necessary to stay compliant with relevant laws. If you continue to use our Services, access our Platform, websites or mobile applications, and/or otherwise provide information to or communicate with us, you will be treated as if you have agreed to the changes.</strong></p>
                <p><strong> </strong></p>
                <h1><strong>12. GENERAL DATA PROTECTION REGULATION (“GDPR”) FOR RESIDENTS OF THE EUROPEAN UNION (“EU”)</strong></h1>
                <p> </p>
                <p>This Privacy Policy describes how Axehedge collects, uses and shares your Personal Data. If you are a resident of the EU, you are subject to the GDPR, which became effective on May 25, 2018, and the following terms also apply to you. Axehedge is the Controller and responsible for your Personal Data. We are based in Malaysia, which is where all of your Personal Data will be processed.</p>
                <p> </p>
                <p><strong>Lawful Bases to Process Your Personal Data.</strong> This Privacy Policy describes the Personal Data we collect from you and how we use it. We will only use your Personal Data when the law allows us to. Most commonly, we will use your Personal Data in the following circumstances:</p>
                <p> </p>
                <p><strong>●</strong>        Where we need to perform the contract we are about to enter into or have entered into with you;</p>
                <p><strong>●</strong>        Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and</p>
                <p><strong>●</strong>        Where we need to comply with a legal or regulatory obligation.</p>

                <p>You have the right to withdraw consent to marketing at any time by contacting us. Specifically, we have a lawful basis for processing each type of Personal Data described in this Privacy Policy, which are as follows:</p>
                <p> </p>
                <p><strong>●</strong>        The lawful basis for collecting your Identity and Contact Data to register you as a new customer is the performance of a contract with you;</p>
                <p><strong>●</strong>        The lawful bases for collecting your Identity, Contact, Financial, Transaction and Marketing and Communications Data to process and deliver your purchases are (a) the performance of a contract with you and (b) it is necessary for our legitimate interests;</p>
                <p><strong>●</strong>        The lawful bases for collecting your Identity, Contact, Profile, and Marketing and Communications Data to manage a relationship with you are (a) the performance of a contract with you and (b) it is necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services);</p>
                <p><strong>●</strong>        The lawful basis for collecting your Identity, Contact, Profile, Usage, and Marketing and Communications Data to enable you to partake in a contest, competition or complete a survey are (a) the performance of a contract with you and (b) it is necessary for our legitimate interests (to study how customers use our products and services, to develop them and grow our business);</p>
                <p><strong>●</strong>        The lawful basis to collect your Identity, Contact, and Technical Data to administer and protect our business and the Service is that it is necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganization or group restructuring exercise);</p>
                <p><strong>●</strong>        The lawful basis to collect your Identity, Contact, Profile, Usage, Marketing and Communications, and Technical Data to deliver relevant Service content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you is that it is necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy);</p>
                <p><strong>●</strong>        The lawful basis to collect Technical and Usage Data to use data analytics to improve our Service, marketing, customer relationships and experiences is that it is necessary for our legitimate interests (to define types of customers for our products and services, to keep our Service updated and relevant, to develop our business and to inform our marketing strategy); and</p>
                <p><strong>●</strong>        The lawful basis to collect Identity, Contact, Profile, Usage, and Technical Data to make suggestions and recommendations to you about goods or services that may be of interest to you is that it is necessary for legitimate interests (to develop our products/services and grow our business).</p>
                <p> </p>
                <p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
                <p> </p>
                <p>Note that we may process your Personal Data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your Personal Data where more than one ground has been set out in this Privacy Policy.</p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p><strong>Sharing Your Personal Data.</strong> We may have to share your Personal Data with the parties set out below for the purposes described in this Privacy Policy.</p>
                <p> </p>
                <p>External Third Parties:</p>
                <p> </p>
                <p><strong>●</strong>        Service providers acting as processors who provide automated marketing services; and</p>
                <p><strong>●</strong>        Professional advisers acting as processors including marketing agents, auditors and insurers who provide consultancy and auditing services</p>
                <p> </p>
                <p>We require all third parties to respect the security of your Personal Data and to treat it in accordance with the law. We do not allow our third-party service providers to use your Personal Data for their own purposes and only permit them to process your Personal Data for specified purposes and in accordance with our instructions.</p>
                <p> </p>
                <p><strong>Data Protection and Security.</strong> We have put in place appropriate security measures to prevent your Personal Data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your Personal Data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your Personal Data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected Personal Data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                <p> </p>
                <p><strong>Data Retention.</strong> We will only retain your Personal Data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for Personal Data, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements. In some circumstances you can ask us to delete your data. In some circumstances we may anonymize your Personal Data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
                <p> </p>
                <p><strong>Your Legal Rights under the GDPR.</strong> Under certain circumstances, EU residents have rights under data protection laws in relation to their Personal Data. If you are an EU resident, you have the right to:</p>
                <p> </p>
                <p><strong>●</strong>        Request access to your Personal Data. This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it;</p>
                <p><strong>●</strong>        Request correction of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us;</p>
                <p><strong>●</strong>        Request erasure of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your Personal Data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request;</p>
                <p><strong>●</strong>        Object to processing of your Personal Data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms;</p>
                <p><strong>●</strong>        Request restriction of processing of your Personal Data. This enables you to ask us to suspend the processing of your Personal Data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it;</p>
                <p><strong>●</strong>        Request the transfer of your Personal Data to you or to a third party. We will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you; and</p>
                <p><strong>●</strong>        Withdraw consent at any time where we are relying on consent to process your Personal Data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
                <p> </p>
                <p>If you wish to exercise any of the rights set out above, please contact us. You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                <p> </p>
                <p><strong>To Summarise: If you are a resident of the EU, you are subject to the GDPR, which became effective on May 25, 2018, and the following terms also apply to you.</strong></p>
                <p> </p>
                <h1><strong>13. CONTACT INFORMATION</strong></h1>
                <p> </p>
                <p>We welcome your comments or questions about this privacy policy. You may also contact us at our address:</p>
                <p> </p>
                <p>Axehedge</p>
                <p>7B, Jalan Tun Mohd Fuad 3,</p>
                <p>Taman Tun Dr. Ismail,</p>
                <p>Kuala Lumpur, Malaysia, 60000</p>
                <p>Telephone: (+60) 377 285 188</p>
                <p>Email: hello@axehedge.com</p>
                <p> </p>
                <p><strong>To Summarise: You may contact us if you have any questions about this Privacy Policy.</strong></p>


            </div>
            <div className="bg-action-blue lg:px-36 divide-y-2 divide-light-color overflow-hidden">
                <EmailSection />
                <SocialSection />
                <div className=" py-6 text-light-grey text-xs md:text-base font-thin px-4 flex flex-row justify-between">
                    <p className='text-white hidden md:block'>Copyright © 2022 AxeHedge. All rights reserved</p>
                    <div className="flex flex-row gap-x-1">
                        <p className="links-footer pr-4" onClick={() => { history.push('/faqs') }}>FAQs</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/general-disclosure') }}>General Disclousre</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/terms-and-conditions') }}>Terms & Conditions</p>
                        <p className="links-footer" onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</p>
                    </div>
                </div>
                <div>
                    <p className='text-white block md:hidden m-3'>Copyright © 2022 AxeHedge. All rights reserved</p>
                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy
