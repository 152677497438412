import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero/Hero";
import Investor from "../components/Investor";
import Reviews from "../components/Reviews";
import WhatWeDO from "../components/WhatWeDo";
import Articles from "../components/Articles";
import StrategiesSlider from "../components/StrategiesSlider";
function LandingPage() {
  return (
    <div>
      <div className="main-theme-color">
        <Header />
        <Hero />
      </div>
      <Reviews />
      <WhatWeDO />
      {/* <Investor /> */}
      <StrategiesSlider />
      <Articles />
      <Footer />
    </div>
  );
}

export default LandingPage;
