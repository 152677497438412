
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import strat1 from '../assets/images/strat1.svg';
import strat2 from '../assets/images/strat2.svg';
import strat3 from '../assets/images/strat3.svg';
import strat5 from '../assets/images/strat5.svg';
import strat6 from '../assets/images/strat6.svg';

function StrategiesSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div className="px-10 md:px-12 lg:px-16 py-10 md:py-12 lg:py-24 overflow-hidden">
      <div className="mb-8 flex justify-center">
        <h2 className="text-2xl md:text-4xl font-bold text-black-origin">AxeHedge Strategies</h2>
      </div>
      <div className="">
        <Slider {...settings} >
          <div>
            <img className="" src={strat1} alt="strategy 1" />
          </div>
          <div>
            <img className="" src={strat2} alt="strategy 2" />
          </div>
          <div>
            <img className="" src={strat3} alt="strategy 3" />
          </div>
          <div>
            <img className="" src={strat3} alt="strategy 3" />
          </div>
          <div>
            <img className="" src={strat5} alt="strategy 4" />
          </div>
          <div>
            <img className="" src={strat6} alt="strategy 5" />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default StrategiesSlider;