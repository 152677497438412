import React from "react";
import logo2 from "../assets/images/reviewLogo2.svg";
import review1 from "../assets/images/pic1.jpg";
import review2 from "../assets/images/pic2.jpg";
import review3 from "../assets/images/pic3.jpg";
import review4 from "../assets/images/pic4.jpg";
import cardLogo from "../assets/images/card-logo.svg";

function Reviews() {
  return (
    <div className="px-2 pb-6 overflow-hidden bg-white shadow-sm md:px-10 md:pb-20 ">
      <div className="flex flex-col text-center">
        <p className="text-4xl font-bold">Our customers love us</p>
        <p className="pt-2 text-2xl text-webmed-grey">
          Here’s What They are Saying
        </p>
      </div>

      <div class="flex flex-wrap -mx-3 overflow-hidden md:-mx-4 lg:-mx-4 xl:-mx-4 pt-10">
        <div class="flex my-3 py-6 px-3 w-full overflow-hidden justify-center md:my-4 md:px-4  md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
          <div class="flex w-full pl-4 pr-4 pt-2  overflow-hidden  rounded-lg shadow-lg sm:flex">
            <div class="flex flex-wrap -mx-3 overflow-hidden ">
              <div class="px-3 w-full h-14 overflow-hidden flex flex-row">
                <span className="">
                  <img
                    class="object-cover h-w-56 rounded-full"
                    src={review1}
                    alt="review1"
                  />
                </span>
                <span className="w-full pt-1 max-h-14 card-header ">
                  <div className="flex w-full justify-items-end">
                    <p className="ml-3 text-sm font-medium font-sans ... text-thin-grey">
                      Harriz
                    </p>
                  </div>
                  <div className="flex items-center mt-2 mb-4 ml-2 text-dark-blue">
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                  </div>
                </span>
                <span className="h-14">
                  <img className="w-12 h-12" src={logo2} alt="logo" />
                </span>
              </div>

              <div class=" px-3 py-3 w-full overflow-hidden sm:-mt-20 lg:-mt-16">
                <p className="pt-8 text-lg font-medium text-black-origin">
                  Kudos to AxeHedge Team
                </p>
                <p className="pt-2 text-thin-grey">
                  App looks amazing, set up is easy and fast. Ive really enjoyed
                  the extensive educational content AxeHedge provides for newbie
                  investors like me. Looking forward to begin my journey!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex my-3 py-6 px-3 w-full overflow-hidden justify-center md:my-4 md:px-4  md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
          <div class="flex w-full pl-4 pr-4 pt-2  overflow-hidden rounded-lg shadow-lg sm:flex">
            <div class="flex flex-wrap -mx-3 overflow-hidden">
              <div class="px-3 w-full h-14 overflow-hidden flex flex-row">
                <span className="">
                  <img
                    class="object-cove h-w-56 rounded-full"
                    src={review2}
                    alt="review2"
                  />
                </span>
                <span className="w-full pt-1 card-header ">
                  <div className="flex w-full justify-items-end">
                    <p className="ml-3 text-sm font-medium text-thin-grey">
                      Qamarina Almas
                    </p>
                  </div>
                  <div className="flex items-center mt-2 mb-4 ml-2 text-dark-blue">
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                  </div>
                </span>
                <span>
                  <img className="w-12 h-12" src={logo2} alt="logo" />
                </span>
              </div>

              <div class="px-3 py-3 w-full overflow-hidden">
                <p className="text-lg font-medium text-black-origin">
                  No. 1 investment companion
                </p>
                <p className="pt-2 text-thin-grey">
                  I like the simple and clean layout. I have access to my
                  favourite stocks, news & analysis, and template strategies.
                  Their backtesting feature is extremely valuable ands it's
                  really cool that I can create trading strategies with
                  zero-code too. What I like most about the app is that I can
                  socialise with other traders - big plus!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex my-3 py-6 px-3 w-full overflow-hidden justify-center md:my-4 md:px-4  md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
          <div class="flex w-full pl-4 pr-4 pt-2  overflow-hidden rounded-lg shadow-lg sm:flex">
            <div class="flex flex-wrap -mx-3 overflow-hidden">
              <div class="px-3 w-full h-14 overflow-hidden flex flex-row">
                <span className="">
                  <img
                    class="object-cover h-w-56 rounded-full"
                    src={review3}
                    alt="review3"
                  />
                </span>
                <span className="w-full pt-1 card-header ">
                  <div className="flex w-full justify-items-end">
                    <p className="ml-3 text-sm font-medium text-thin-grey">
                      Marc Raphael Joseph
                    </p>
                  </div>
                  <div className="flex items-center mt-2 mb-4 ml-2 text-dark-blue">
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                  </div>
                </span>
                <span>
                  <img className="w-12 h-12" src={logo2} alt="logo" />
                </span>
              </div>
              <div class="px-3 py-3 w-full overflow-hidden">
                <p className="text-lg font-medium text-black-origin">
                  Very user friendly and informative
                </p>
                <p className="pt-2 text-thin-grey">
                  Having a background in the Food & Beverage industry, I've
                  always wanted to explore trading but have always come across
                  technical terms and blurry information on other apps, AxeHedge
                  has definitely helped simplify and explain many terms that
                  would otherwise confuse people!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex my-3 py-6 px-3 w-full overflow-hidden justify-center md:my-4 md:px-4  md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
          <div class="flex w-full pl-4 pr-4 pt-2  overflow-hidden rounded-lg shadow-lg sm:flex">
            <div class="flex flex-wrap -mx-3 overflow-hidden">
              <div class="px-2 w-full h-16 overflow-hidden flex flex-row">
                <div className="">
                  <img
                    className="rounded-full h-w-56"
                    src={review4}
                    alt="review4"
                  />
                </div>
                <span className="w-full pt-1 card-header ">
                  <div className="flex w-full justify-items-end">
                    <p className="ml-3 text-sm font-medium text-thin-grey">
                      Cecilia Tok Yen Shin
                    </p>
                  </div>
                  <div className="flex items-center mt-2 mb-4 ml-2 text-dark-blue">
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                    <svg
                      className="w-4 h-4 mx-1 fill-current text-web-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                    </svg>
                  </div>
                </span>
                <span>
                  <img className="w-12 h-12" src={logo2} alt="logo" />
                </span>
              </div>
              <div class="px-3 w-full overflow-hidden">
                <p className="text-lg font-medium text-black-origin">
                  An app for the younger generation!
                </p>
                <p className="pt-2 text-thin-grey">
                  Being young and adventurous, wanting to take risks financially
                  to grow my income can always be scary, AxeHedge trully does
                  help make things much easier in terms of making calculated
                  choices! All while educating in the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between h-auto my-10 -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-1">
        <div className="flex w-full px-1 pb-6 my-1 overflow-hidden align-center lg:pb-0 lg:pt-2 sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
          <p className="w-full h-auto pt-2 text-4xl font-bold text-center lg:w-3/4 lg:text-left xl:text-6xl">
            We're inclusive,<br></br> fast and decisive
          </p>
        </div>
        <div className="flex justify-center w-full px-1 my-1 lg:justify-end lg:w-1/2 sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 xl:my-1 xl:px-1 xl:w-1/2">
          <div class="flex w-full md:w-2/3 lg:w-full xl:w-3/4 px-4 py-6 overflow-hidden rounded-lg shadow-lg">
            <div className="flex">
              <div className="grow-1">
                <p className="w-full xl:text-xl md:w-4/5 xl:w-full">
                  We’re on a mission to democratize the hedge fund industry and
                  make their solutions accessible to all
                </p>
              </div>
              <div className="mx-4">
                <img className="w-20 h-12 " src={cardLogo} alt="review1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
