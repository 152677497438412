
import { useState } from "react";
import { validEmail } from "../../Utils/Validations.js";
import axios from "axios";

import { successMessage } from "../../global";

const Email = ({isFooter}) => {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Please enter email address.");
      return;
    } else if (validEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Please enter valid email address.");
      return;
    }
    setEmailSuccess(true);

    axios
      .post(
        "https://sheet.best/api/sheets/f8e25cb5-7aa4-445b-81ec-5ce795aff1c5",
        { Email: email }
      )
      .then((response) => {
        console.log("Response:", response);
      })
      .catch((error) => console.log("Error:", error.message));
  };

  return (
    <div className="relative w-full overflow-hidden">
      {!emailSuccess ? (

        <div>
          <div className="absolute top-4 left-3">
            {" "}
            <i className="z-20 text-gray-400 fa fa-search hover:text-gray-500"></i>{" "}
          </div>{" "}
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="z-0 w-full pl-4 text-sm border rounded h-14 md:pl-10 lg:pl-6 pr-36 md:pr-52 border-thin-grey focus:shadow focus:outline-none"
            placeholder="email@domain.com"
          />
          <div className="absolute top-0 right-0">
            {" "}
            <button
              onClick={(event) => handleSubmit(event)}
              className="items-center justify-between pt-3 pb-4 text-sm font-bold bg-red-500 rounded h-14 w-32 md:h-14 md:w-36  xl:w-44 bg-dark-blue hover:bg-red-600 place-content-between"
            >
                <span className="justify-center items-center text-white ">{isFooter === true?'Join Now':'Subscribe to our Newsletter '}</span>
            </button>{" "}
          </div>
          <span>
            {emailError && (
              <p className="pl-2 text-sm text-danger-color">{emailErrorText}</p>
            )}
          </span>{" "}
        </div>
      ) : (
        <div className="z-0 w-full h-12 rounded-3xl bg-light-green ">
          <p className="text-sm md:text-lg flex items-center justify-center pt-2.5">
            {successMessage}
          </p>
        </div>
      )}
    </div>
  );
};

export default Email;
