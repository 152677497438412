import OldEmail from "../../Shared/OldEmail";
const EmailSection = () => {
  return (
    <div className="pt-8 md:pt-4 pb-5 flex flex-wrap px-4 overflow-hidden">
      <div className="w-full sm:my-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
        <h1 className="text-4xl text-left font-bold text-black">
          Get Early Access
        </h1>
        <p className="text-white pt-2 text-lg font-normal">
          When AxeHedge goes public
        </p>
      </div>
      <div className="w-full pt-4 md:pt-0 sm:my-3 md:w-1/2 lg:w-1/2 xl:w-1/2 flex items-center">
        <OldEmail />
      </div>
    </div>
  );
};

export default EmailSection;
