import React from 'react'
import { signin, signup } from "../global";
import whiteaxehedge from "../assets/images/whiteaxehedge.svg";
import EmailSection from '../components/OldFooter/Footer/EmailSection';
import SocialSection from '../components/Footer/SocialsSection';
import { useHistory } from 'react-router';
function GeneralDisclousre() {
    const history = useHistory()
    return (
        <>
            <div className="main-theme-color h-20-vh">
                <div className="flex px-4 md:px-16 lg:px-36 pt-4 place-content-between overflow-hidden ">
                    <img src={whiteaxehedge} alt="" />
                    <div className="place-content-between">
                        {/* <a href={signin}>
                            <button className="h-12 w-24 hidden md:inline-block lg:w-32 text-base font-semibold justify-between text-action-blue   hover:text-web-yellow">
                                Login
                            </button>
                        </a>
                        <a href={signup}>
                            <button className="h-12 w-24 lg:w-32 text-base text-action-blue font-semibold justify-between bg-white hover:bg-web-yellow b-r-5">
                                Register
                            </button>
                        </a> */}
                    </div>
                </div>

                <div className="flex px-4 md:px-16 lg:px-36 custom-p-134 place-content-between  ">
                    <h1 className="text-white text-5xl poppins-font "> {"General Disclosures"}</h1>
                </div>
            </div>
            <div className="py-6 block px-4 md:px-16 lg:px-36  place-content-between space-y-4 secondary-text" >
                <p><strong>INTRODUCTION</strong></p>
                <p> </p>
                <p>This Statement seeks to provide you with an overview of disclosures and risks associated with the services provided to you by Axehedge Sdn. Bhd. (Company No.  202101025223) ("Axehedge", “Company”, “we”, “us”, “our”). Your need to know and understand the risks involved in any transaction you may undertake. This is of particular importance to you because of the scope of our services to you.</p>
                <p> </p>
                <p>By using our services pursuant to your agreement(s) with us ("Services"), accessing the online platform operated by us (which is accessible through our website at www.axehedge.com or through our mobile application) ("Platform"), websites or mobile applications, or otherwise providing information to or communicating with us, you are taken to have agreed to our disclosures and risks associated with the services provided by us.</p>
                <p> </p>
                <p><strong>OUR SERVICES</strong></p>
                <p><strong> </strong></p>
                <p>Certain services offered through our platform are offered only in jurisdictions where it is legal to do so. The availability of these services over the Internet is not a solicitation for or offering of the Services to any person in any jurisdiction where such solicitation or offering is illegal.</p>
                <p> </p>
                <p><strong>LIMITATION OF SERVICES</strong></p>
                <p><strong> </strong></p>
                <p>We reserve the right to limit the availability of services to any person, geographic area, or jurisdiction, at any time and at our sole discretion. We make no representation that the services are appropriate or available for use in locations outside of Malaysia or that accessing our platform is legally permitted in countries or territories where the services may be illegal. If you access these services from other locations, you do so at your own risk and are responsible for compliance with local laws.</p>
                <p> </p>
                <p><strong>USE OF TRADEMARKS AND LOGOS</strong></p>
                <p> </p>
                <p>Trademarks and logos are the property of their respective owners and do not represent endorsements of any kind.</p>
                <p><strong> </strong></p>
                <p><strong> </strong></p>
                <p><strong> </strong></p>
                <p><strong> </strong></p>
                <p> </p>
                <p><strong>NO INVESTMENT ADVICE; NOT AN INVESTMENT ADVISOR; NOT A BROKER DEALER</strong></p>
                <p> </p>
                <p>The Company is not an investment advisory service, nor is it a registered investment advisor or broker-dealer and does not purport to tell or suggest the value of any securities or which securities customers should buy or sell for themselves. The users of the platform may hold positions in the stocks or industries discussed here. You understand and acknowledge that there is a very high degree of risk involved in trading securities. The Company, the users, the authors, the publisher, and all affiliates of the platform assume no responsibility or liability for your trading and investment results. Factual statements or publications on the platform are made as of the date stated and are subject to change without notice. It should not be assumed that the methods, techniques, or indicators presented in these products will be profitable or that they will not result in losses. Past results of any individual trader or trading system published on the platform are not indicative of future returns by that trader or system, and are not indicative of future returns which will be realized by you. In addition, the indicators, strategies, columns, articles and all other features of the platform are provided for informational and educational purposes only and should not be construed as investment advice. Examples presented on the platform are for educational purposes only. Such set-ups are not solicitations of any order to buy or sell. Accordingly, you should not rely solely on the Information in making any investment. You should always check with your licensed financial advisor and tax advisor to determine the suitability of any investment.</p>
                <p> </p>
                <p>Content, news, research, tools, market data and securities symbols are for educational and illustration purposes only and are not substitutes for professional advice. All information provided is provided in good faith. We make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the website, mobile application or associated social media pages.</p>
                <p> </p>
                <p><strong>LIVE TRADING RISKS</strong></p>
                <p> </p>
                <p>By using the platform, you acknowledge and agree that live algorithmic trading has various risks and the Company shall not have any liability for any losses incurred through using the platform, such risks included, without limitation:</p>
                <p> </p>
                <ul className="list-disc pl-4">
                    <li>Design risks; errors in data, incorrect assumptions, logical program errors. Even if you designed a perfect strategy, past performance is no guarantee of future results;</li>
                    <li>Loss of internet connectivity; resulting in disconnections from brokerage, trades not placed, not seeing the results of algorithm, orders failing to be placed;</li>
                    <li>Poor design of algorithm; resulting in runaway strategies, machine gun orders, run-time errors halting program operation, failure to trade when expected, termination of the algorithm;</li>
                    <li>Unexpected market conditions; resulting in poor fill prices, rapid losses (flash crashes), late exchange openings;</li>
                    <li>Data issues; broken, dirty, delayed or intermittent data connections causing algorithm errors. This includes data inaccuracies (exchanges sometimes publish bad/fictional trades);</li>
                    <li>Execution issues; orders submissions, updates and cancellations could be rejected or delayed. Trades could be disallowed by authorities without warning;</li>
                    <li>Malicious activities; criminal activity could cause your algorithm to fail or your brokerage account to become compromised, leaking personal information, intellectual property or theft of your funds; or</li>
                    <li>These events can cause the loss of all funds and holdings in your brokerage account. Algorithmic trading losses can occur faster than in manual trading and you should consult an investment professional to discuss these risks. You should continually monitor the operation of a live trading algorithm to ensure it is running properly.</li>
                </ul>
                <p> </p>
                <p><strong>EVERY TIME YOU USE THE PLATFORM YOU AGREE THAT IN ALL CASES THE COMPANY BEARS NO RESPONSIBILITY FOR LOSSES INCURRED, AND OFFERS NO GUARANTEES OR EXPECTATIONS ON YOUR ALGORITHM PERFORMANCE OR STABILITY.</strong></p>

            </div >
            <div className="bg-action-blue lg:px-36 divide-y-2 divide-light-color overflow-hidden">
                <EmailSection />
                <SocialSection />
                <div className=" py-6 text-light-grey text-xs md:text-base font-thin px-4 flex flex-row justify-between">
                    <p className='text-white hidden md:block'>Copyright © 2022 AxeHedge. All rights reserved</p>
                    <div className="flex flex-row gap-x-1">
                        <p className="links-footer pr-4" onClick={() => { history.push('/faqs') }}>FAQs</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/general-disclosure') }}>General Disclousre</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/terms-and-conditions') }}>Terms & Conditions</p>
                        <p className="links-footer" onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</p>
                    </div>
                </div>
                <div>
                    <p className='text-white block md:hidden m-3'>Copyright © 2022 AxeHedge. All rights reserved</p>
                </div>
            </div>
        </>
    )
}

export default GeneralDisclousre
