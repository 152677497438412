
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import benchmark from "../assets/images/trending-up.svg";

function Investor() {
    const [investedCapital, setInvestedCapital] = useState(0);
    const [time, setTime] = useState(1);
    const [netReturn, setNetReturn] = useState(1);


    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: "basic-bar",
                foreColor: "#ccc",
            },

            grid: {
                borderColor: "#555",
                clipMarkers: false,
                yaxis: {
                    lines: {
                        show: false,
                    },
                },

            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',


            },
            yaxis: {
                labels: false,
            },
            xaxis: {
                tooltip: {
                    enabled: false
                }
            },
            xaxis: {
                labels: {
                    show: false
                }
            },

            fill: {
                gradient: {
                    enabled: true,
                    opacityFrom: 0.55,
                    opacityTo: 0
                }
            },

        },
        series: [
            {
                name: "AxeHedge",

                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },

            {
                name: "S&P 500",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            }
        ],

    });

    const capitalHandler = (e) => {
        e.preventDefault();
        const val = e.target.value;
        setInvestedCapital(val);

        if (val > 0) {

            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        chart: {
                            id: "basic-bar",
                            foreColor: "#ccc",
                        },

                        grid: {
                            borderColor: "#555",
                            clipMarkers: false,
                            yaxis: {
                                lines: {
                                    show: false,
                                },
                            },

                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: 'straight',


                        },
                        yaxis: {
                            labels: false,
                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },

                        fill: {
                            gradient: {
                                enabled: true,
                                opacityFrom: 0.55,
                                opacityTo: 0
                            }
                        },

                    },

                    series: [
                        {
                            name: "AxeHedge",

                            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                        },

                        {
                            name: "S&P 500",

                            data: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220],
                        }
                    ],

                }
            })

        }
        else {
            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        chart: {
                            id: "basic-bar",
                            foreColor: "#ccc",
                        },

                        grid: {
                            borderColor: "#555",
                            clipMarkers: false,
                            yaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                            xaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                            xaxis: {
                                tooltip: {
                                    enabled: false
                                }
                            },
                            xaxis: {
                                labels: {
                                    show: false
                                }
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: 'straight',


                        },
                        yaxis: {
                            labels: false,
                        },
                        xaxis: {
                            labels: false,
                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },

                        fill: {
                            gradient: {
                                enabled: true,
                                opacityFrom: 0.55,
                                opacityTo: 0
                            }
                        },

                    },
                    series: [
                        {
                            name: "AxeHedge",

                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },

                        {
                            name: "S&P 500",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        }
                    ],

                }
            })

        }

    };
    const timeHandler = (e) => {
        e.preventDefault();
        const val2 = e.target.value;
        setTime(val2);

        if (val2 > 2) {
            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',

                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },
                    },
                    series: [
                        {
                            name: "AxeHedge",
                            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        },
                        {
                            name: "S&P 500",
                            data: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
                        }
                    ],

                }
            })
        }
        if (val2 > 8) {
            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',

                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },
                    },
                    series: [
                        {
                            name: "AxeHedge",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            name: "S&P 500",
                            data: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
                        }
                    ],

                }
            })
        }

        if (val2 > 20) {
            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',

                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },
                    },
                    series: [
                        {
                            name: "AxeHedge",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            name: "S&P 500",
                            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        }
                    ],

                }
            })
        }
    };
    const netReturnHandler = (e) => {
        e.preventDefault();
        const val3 = e.target.value;
        setNetReturn(val3);

        if (val3 > 5) {

            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',

                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },

                    },

                    series: [
                        {
                            name: "AxeHedge",

                            data: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220],
                        },

                        {
                            name: "S&P 500",


                            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                        }
                    ],

                }
            })

        }

        if (val3 > 10) {

            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',

                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },

                    },

                    series: [
                        {
                            name: "AxeHedge",

                            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                        },

                        {
                            name: "S&P 500",


                            data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
                        }
                    ],

                }
            })

        }

        if (val3 > 20) {

            setChartData((prev) => {
                return {
                    ...prev,
                    options: {
                        stroke: {
                            curve: 'straight',
                        },
                        xaxis: {
                            tooltip: {
                                enabled: false
                            }
                        },
                        xaxis: {
                            labels: {
                                show: false
                            }
                        },

                    },
                    series: [
                        {
                            name: "AxeHedge",

                            data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
                        },

                        {
                            name: "S&P 500",

                            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        }
                    ],

                }
            })

        }
    };

    const wealth = investedCapital * (netReturn / 100 + 1) ** 10;
    const totalWealth = Math.trunc(wealth);

    let nfObject = new Intl.NumberFormat('en-US');
    let outputTotalWealth = nfObject.format(totalWealth);

    let outputInvestedCapital = nfObject.format(investedCapital);

    const returnOnCapital = totalWealth === 0 ? 0 : totalWealth / investedCapital;
    let totalReturn = 0;
    totalReturn = returnOnCapital === 0 ? 0 : returnOnCapital.toFixed(1);
    let benchMark = (1.07 ** 10) * investedCapital;
    let benchMarkReturn = benchMark.toFixed(0);
    const profitAboveBenchMark = totalWealth - benchMarkReturn;

    let outputProfitAboveBenchMark = nfObject.format(profitAboveBenchMark);

    return (
        <div className="flex flex-col py-24 overflow-hidden xs:items-center lg:flex-row xl:px-20 md:px-10">
            <div className="p-2 xl:p-8 lg:w-2/3">
                <div className="text-4xl font-semibold text-black-origin xs:text-center lg:text-left">
                    <p>Investing with discipline</p>
                </div>
                <div className="pt-8 text-lg text-other-grey xs:w-full sm:px-8 lg:px-0 lg:w-2/3 xs:text-center lg:text-justify">
                    <p>
                        We help you remove emotions from trading. You’ll quickly find that
                        we make a great trading companion. Set a plan that aligns with your
                        behaviour and risk to reward expectations. Backtest your trading
                        plan and strategy. Finally, follow the plan with a total peace of
                        mind
                    </p>
                </div>
                <div className="justify-center pt-6 text-lg font-bold text-action-blue xs:text-center lg:text-left">
                    <p>Total Calculations</p>

                </div>
                <hr className="flex justify-center mt-4 solid xs:w-full lg:w-1/2"></hr>

                <div className="pt-2 text-lg font-medium xs:text-center lg:text-left xs:w-full lg:w-1/2">
                    <p className="flex justify-between py-4">
                        AxeHedge <strong className="text-action-blue">+26.5%</strong>
                    </p>
                    <hr className="mt-2 divider-solid"></hr>
                    <p className="flex justify-between py-4">
                        {" "}
                        S&amp;P500 <strong className="text-action-blue">+17.7%</strong>
                    </p>
                    <hr className="mt-2 divider-solid"></hr>
                    <p className="flex justify-between py-4">
                        Average Investor <strong className="text-action-blue">+6.7%</strong>
                    </p>
                </div>
            </div>

            <div className="">
                <div className="justify-center mb-4 overflow-hidden shadow-lg xs:w-80 sm:w-96 rounded-2xl">
                    <div className="bg-dark-blue h-96">
                        <div className="w-full h-auto px-4 pt-6">
                            <div className="flex justify-between w-full">
                                <p className="w-1/2 text-sm p-yellow text-web-yellow">
                                    Wealth Calculator
                                </p>

                                <div className="inline-flex w-auto p-yellow text-green text-md">
                                    <img class="w-auto" src={benchmark} alt="benchmark" /> <p className="mx-2">{outputProfitAboveBenchMark}$</p>

                                </div>
                            </div>

                            <div className="flex justify-between text-white">
                                <h1 className="pt-2 text-3xl font-bold ">
                                    ${outputTotalWealth}
                                </h1>
                                <p className="text-sm">
                                    Profits Benchmark
                                </p>
                            </div>

                            <p className="pt-2 text-sm text-white p-yellow">
                                Total Invested Capital: ${outputInvestedCapital}
                            </p>

                            <p className="pt-2 text-sm text-white p-yellow">
                                Return on Capital: {Number(totalReturn)}x
                            </p>
                        </div>

                        <div className="flex items-start overflow-hidden">
                            {netReturn && time && investedCapital !== 0 && <ReactApexChart options={chartData.options} series={chartData.series} type='area' height={200} width={420} />}
                        </div>
                    </div>
                    <div className="h-auto px-4 pb-4 poppins-font">
                        <div className="px-2 pt-4 pb-2">
                            <span className="flex justify-between pt-2 pb-4">
                                <p className="text-sm font-semibold text-black-light">
                                    Capital Invested Today
                                </p>

                                <p className="text-sm font-semibold text-range-values text-black-light">
                                    ${outputInvestedCapital}
                                </p>
                            </span>

                            <input
                                className="w-full"
                                type="range"
                                min="0"
                                max="50000"
                                step="500"
                                onChange={(e) => capitalHandler(e)}
                            />
                        </div>

                        <div className="px-2 pb-2">
                            <span className="flex justify-between pt-2 pb-4">
                                <p className="text-sm font-semibold text-black-light">
                                    Time Horizon
                                </p>

                                <p className="text-sm font-semibold text-range-values text-black-light">
                                    {time} year
                                </p>
                            </span>

                            <input
                                className="w-full"
                                type="range"
                                value={time}
                                min="1"
                                max="50"
                                step="1"
                                onChange={(e) => timeHandler(e)}
                            />
                        </div>

                        <div className="px-2 pb-2">
                            <span className="flex justify-between pt-2 pb-4">
                                <p className="text-sm font-semibold text-black-light">
                                    Net Annualized Return
                                </p>

                                <p className="text-sm font-semibold text-range-values text-black-light">
                                    {netReturn}%
                                </p>
                            </span>

                            <input
                                className="w-full"
                                type="range"
                                value={netReturn}
                                min="1"
                                max="30"
                                step="1"
                                onChange={(e) => netReturnHandler(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Investor;
