import { Route, Switch, BrowserRouter } from "react-router-dom";
import FaqList from "./pages/FaqList";
import FAQs from "./pages/FAQs";
import GeneralDisclousre from "./pages/GeneralDisclousre";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route
            exact
            path="/general-disclosure"
            component={GeneralDisclousre}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/faqs" component={FaqList} />
          <Route exact path="/faqs/:id" component={FAQs} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
