import heroMobile from "../../assets/images/mobile.jpg";
const HeroRightSide = () => {
  return (
    <div className="flex justify-center pt-0 lg:px-0 w-full lg:w-1/2 overflow-hidden hero-background pt-10">
      <img className="hero-image lg:p-0 md:p-10 " src={heroMobile} alt="" />
    </div>
  );
};

export default HeroRightSide;
