import { useEffect, useState } from "react";
import { getAllFaq } from "../../API/getFAQs.js";

const FaqSearch = ({ setDataFaq, dataFaq }) => {
  const [email, setEmail] = useState("");
  const [data, setData] = useState();

  const getFaqs = async () => {
    try {
      const result = await getAllFaq();
      if (result.status === 200) {
        if (result.data.is_success) {
          setData(result.data.data);
        } else {
          console.log("staus 200 result false", result.data);
        }
      } else {
        console.log(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFaqs();
    // eslint-disable-next-line
  }, []);
  return (
    <div className=" relative w-full  overflow-hidden">
      <div className="relative inset-y-0 right-12 flex justify-center">
        <span class="relative inset-y-0 left-12 flex items-center pl-2 searchIcon-color">
          <button
            type="submit"
            class="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </span>

        <input
          type="search"
          name="email"
          value={email}
          onChange={(e) => {
            setDataFaq(
              data.filter((item) => {
                console.log(item.question);
                let a = item.question
                  .toUpperCase()
                  .match(e.target.value.replace(/[^\w\s]/gi, "").toUpperCase());
                return a && a.length > 0 ? true : false;
              })
            );
            setEmail(e.target.value);
          }}
          className="  h-12 w-1/2  pl-11 md:pl-10 lg:pl-12 rounded-3xl  border-2 text-sm border-light-color focus:shadow focus:outline-none"
          placeholder={"Search"}
          Input
        />
      </div>
    </div>
  );
};

export default FaqSearch;
