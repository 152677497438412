// Images
import stocktradingimage from "./assets/images/stocktrading.svg";
import statsImage from "./assets/images/stats.svg";
import portfolioImage from "./assets/images/portfolio.svg";
import lastStockImage from "./assets/images/stock.svg";
import logoImage from "./assets/images/logo.svg";
import heroImage from "./assets/images/hero.svg";

import alarmImage from "./assets/images/alarm.svg";
import gearImage from "./assets/images/gear.svg";
import runningImage from "./assets/images/running.svg";
import statisticsImage from "./assets/images/statistics.svg";
import universeImage from "./assets/images/universe.svg";
import resultImage from "./assets/images/test-results.svg";

import whiteLogoImage from "./assets/images/logo-white.svg";
import facebookImage from "./assets/images/facebook.svg";
import twitterImage from "./assets/images/twitter.svg";

import pointsTableImage from "./assets/images/points-table.svg";
import cumulativePerformanceImage from "./assets/images/S&P.svg";
import performanceIndicatorImage from "./assets/images/graph.svg";

import img1_ from "./assets/images/img1.png";
import img2_ from "./assets/images/img2.png";
import img3_ from "./assets/images/img3.png";

import upperXImage from "./assets/images/upper-x.svg";
import bottomXImage from "./assets/images/bottom-x.svg";

const SIGNIN_URL = process.env.REACT_APP_PORTAL_URL;
const SIGNUP_URL = process.env.REACT_APP_PORTAL_REGISTER_URL;

let stocktrading = stocktradingimage;
let stats = statsImage;
let portfolio = portfolioImage;
let lastStock = lastStockImage;
let logo = logoImage;
let hero = heroImage;

let alarm = alarmImage;
let gear = gearImage;
let running = runningImage;
let statistics = statisticsImage;
let universe = universeImage;
let result = resultImage;

let logoWhite = whiteLogoImage;
let facebook = facebookImage;
let twitter = twitterImage;

let pointsTable = pointsTableImage;
let cumulativePerformance = cumulativePerformanceImage;
let performanceIndicator = performanceIndicatorImage;

let img1 = img1_;
let img2 = img2_;
let img3 = img3_;

let upperx = upperXImage;
let bottomx = bottomXImage;

let signin = SIGNIN_URL;
let signup = SIGNUP_URL;

// Text
let successMessage =
  "Thank you for sharing your email, you are added to the list.";

// Spread Sheet Credentials

const SPREADSHEET_ID = "1mn30UpeRyDg9RTSe588xJdZNVIg9YWUKqjv3DNFcy2I";
const CLIENT_ID =
  "922055333672-2hpjp0i34cp11jcrbaah1b4nj00htvqp.apps.googleusercontent.com";
const API_KEY = "AIzaSyCGToXLpXaLLrvAWQLmSDHoYMTZCe5dgbM";
const SCOPE = "https://www.googleapis.com/auth/spreadsheets";

export {
  stocktrading,
  stats,
  portfolio,
  lastStock,
  logo,
  hero,
  alarm,
  gear,
  running,
  statistics,
  universe,
  result,
  logoWhite,
  facebook,
  twitter,
  pointsTable,
  cumulativePerformance,
  performanceIndicator,
  successMessage,
  SPREADSHEET_ID,
  CLIENT_ID,
  API_KEY,
  SCOPE,
  img1,
  img2,
  img3,
  upperx,
  bottomx,
  signin,
  signup,
};
