import React from "react";
import { signin, signup } from "../global";
import logoAxe from "../assets/images/axe-logo.svg";
const Header = () => {
  return (
    <div className="bg-white">
      <div className="flex px-4 pt-4 pb-4 overflow-hidden md:px-16 lg:px-36 place-content-between ">
        <img className="sm:w-24 md:w-64" src={logoAxe} alt="" />
        <div className="place-content-between">
          <a href={signup}>
            <button className="justify-between w-24 h-8 text-base font-normal text-white bg-dark-blue hover:bg-dark-sky b-r-5">
              Join Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Header;
