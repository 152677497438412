import Email from "../Shared/Email";
import logoAxe from "../../assets/images/axe-logo.svg";
const EmailSection = () => {
  return (
    <div className="pt-8 md:pt-4 pb-5 flex flex-wrap px-4 overflow-hidden">
      <div className="w-full sm:my-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
        <img className="sm:w-24 md:w-64" src={logoAxe} alt="" />
      </div>
      <div className="w-full pt-4 md:pt-0 sm:my-3 md:w-1/2 lg:w-1/2 xl:w-1/2 flex items-center">
        <Email isFooter={true} />
      </div>
    </div>
  );
};

export default EmailSection;
