import { facebook, twitter } from "../../global";
import logoAxe from "../../assets/images/whiteaxehedge.svg";

const SocialSection = () => {
  return (
    <div className="flex px-4 pt-4 pb-4 overflow-hidden place-content-between ">
      <div className="w-40 md:w-full ">
        <a href="https://axehedge.com">
          <img src={logoAxe} alt="" />
        </a>
      </div>
      <div className="flex items-center justify-between hidden w-24 md:w-32">
        <a href="https://axehedge.com">
          <img src={facebook} alt="" className="mr-5 " />
        </a>
        <a href="https://axehedge.com">
          <img src={twitter} alt="" className="" />
        </a>
      </div>
    </div>
  );
};

export default SocialSection;
