import React from 'react'
import { signin, signup } from "../global";
import whiteaxehedge from "../assets/images/whiteaxehedge.svg";
import EmailSection from '../components/OldFooter/Footer/EmailSection';
import SocialSection from '../components/Footer/SocialsSection';
import { useHistory } from 'react-router';

function TermsAndConditions() {
    const history = useHistory()
    return (
        <>
            <div className="main-theme-color h-20-vh">
                <div className="flex px-4 md:px-16 lg:px-36 pt-4 place-content-between overflow-hidden ">
                    <img src={whiteaxehedge} alt="" />
                    <div className="place-content-between">
                        {/* <a href={signin}>
                            <button className="h-12 w-24 hidden md:inline-block lg:w-32 text-base font-semibold justify-between text-action-blue   hover:text-web-yellow">
                                Login
                            </button>
                        </a>
                        <a href={signup}>
                            <button className="h-12 w-24 lg:w-32 text-base text-action-blue font-semibold justify-between bg-white hover:bg-web-yellow b-r-5">
                                Register
                            </button>
                        </a> */}
                    </div>
                </div>

                <div className="flex px-4 md:px-16 lg:px-36 custom-p-134 place-content-between  ">
                    <h1 className="text-white text-5xl poppins-font "> {"Terms & Conditions"}</h1>
                </div>
            </div>
            <div className="py-6 block px-4 md:px-16 lg:px-36  place-content-between space-y-4 secondary-text">
                <p><strong>{"INTRODUCTION TO THESE TERMS & CONDITIONS"}</strong></p>
                <p> </p>
                <p>These terms and conditions (the “Terms and Conditions'') apply to the provision of certain services to you (“you“) by Axehedge Sdn. Bhd. (Company No.  202101025223) (“Axehedge'', “Company”, “we“, “us“, “our“) or any of their derivatives that refer to Axehedge and its successors and any novatee, assignee, transferee or purchaser of Axehedge's rights and/or obligations hereunder and any reference to Axehedge includes a reference to such successor, novatee, assignee, transferee or purchaser. The Terms and Conditions contain the core information defining our business relationship with you. Axehedge is a company incorporated and registered in Malaysia.</p>
                <p> </p>
                <p>These Terms and Conditions contain important information regarding the services that we will provide to you for your own protection. You should read them carefully before accepting them. Please let us know as soon as possible if there is anything which you do not understand or please visit our Website at www.axehedge.com and ask for further information. These Terms and Conditions together with any schedule(s), annexes, accompanying documents and/or supplemental agreements (as may be amended from time to time) form an agreement between you and Axehedge (the “Agreement“).</p>
                <p> </p>
                <p>The online platform operated by us (which is accessible through our website at www.axehedge.com or through our mobile application) ("Platform"), are both copyrighted and belong to Axehedge. Certain features of the Platform may be subject to additional guidelines, terms, or rules, which will be posted on the Platform in connection with such features, including, without limitation the Company’s Privacy Policy, the Subscription Agreement and the Sublicense Agreement, all of which are incorporated by reference to these Terms. All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>
                <p> </p>
                <p>BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICES PROVIDED BY US, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICES, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING AXE HEDGE’S PRIVACY POLICY. IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS AND CONDITIONS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICES.</p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p><strong>1. ACCOUNTS</strong></p>
                <p> </p>
                <p><strong>Account Creation.</strong> In order to use certain features of the Platform, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form. You represent and warrant that:</p>
                <p>●        (a) all required registration information you submit is truthful and accurate;</p>
                <p>●        (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Platform. The Company may suspend or terminate your Account in accordance with Section 8.</p>
                <p> </p>
                <p><strong>Account Responsibilities.</strong> You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify the Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
                <p> </p>
                <p><strong>Non-Solicitation. </strong>You are prohibited from soliciting or recruiting any other users or members of the Platform, including external strategy creators with whom the Company have agreements with, for any reason, unless you have requested and received permission to solicit or recruit in writing from the Company. This prohibition includes a prohibition against soliciting or recruiting users of the Platform, including external strategy creators with whom the Company have agreements with, to become members of competitive Platforms and services. This includes (but is not limited to) soliciting external strategy creators with whom the Company have agreements with to make their methods, devices, models, algorithms or other automated processes available at services or Platforms outside of the Platform. If you violate this policy, the Company reserves the right to terminate your Account without notice and to take legal action to recover lost revenues you have stolen from us, plus applicable damages. By using the Platform, you specifically acknowledge that you agree to this prohibition.</p>
                <p> </p>
                <p><strong>2. ACCESS TO THE PLATFORM</strong></p>
                <p><strong> </strong></p>
                <p><strong>License. </strong>Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Platform solely for your own personal use.</p>
                <p> </p>
                <p><strong>Certain Restrictions.</strong> The rights granted to you in these Terms are subject to the following restrictions:</p>
                <ol className="list-disc pl-4"><li> you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Platform, whether in whole or in part, or any content displayed on the Platform;</li>
                    <li>you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Platform;</li>
                    <li>you shall not access the Platform in order to build a similar or competitive website, product, or service; and</li>
                    <li>except as expressly stated herein, no part of the Platform may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Platform shall be subject to these Terms. All copyright and other proprietary notices on the Platform (or on any content displayed on the Platform) must be retained on all copies thereof.</li></ol>
                <p> </p>
                <p><strong>Modification.</strong> Company reserves the right, at any time, to modify, suspend, or discontinue the Platform (in whole or in part) with or without notice to you. You agree that the Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Platform or any part thereof.</p>
                <p> </p>
                <p><strong>No Support or Maintenance.</strong> You acknowledge and agree that Company will have no obligation to provide you with any support or maintenance in connection with the Platform, but the Company reserves the right, on a case by case basis, to provide support or maintenance as the need arises.</p>
                <p> </p>
                <p><strong>Ownership. </strong>Subject to the terms hereof, and excluding any Non-Public User Content that you may provide (defined below in Section 3.0), you acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Platform and its content, whether provided by you, the Company, or Company’s suppliers, are owned by Company or Company’s suppliers. Neither these Terms (nor your access to the Platform) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.</p>
                <p> </p>
                <p><strong>3. USER CONTENT</strong></p>
                <p> </p>
                <p><strong>User Content. </strong>“User Content” means any and all information submitted to or communicated through the Platform. You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party. You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 3.3). You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by the Company. Because you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. Company is not obligated to backup any User Content, and your User Content may be deleted at any time without prior notice. You are solely responsible for creating and maintaining your own backup copies of your User Content if you desire.</p>
                <p> </p>
                <p><strong>Non-Public User Content. </strong>User Content includes Non-Public User Content and Public User Content. Non-Public User Content is User Content that you communicate directly to one or more other users of the Platform or that is not accessible without being logged into your account. Public User Content is all other User Content. When you submit Public User Content, you agree that all rights in such Public User Content are owned by the Company per Section 2.5 above and agree to take reasonable steps, if any, necessary to effectuate such ownership.</p>
                <p> </p>
                <p><strong>Acceptable Use Policy. </strong>The following terms constitute our “Acceptable Use Policy”:</p>
                <p> </p>
                <p>(a) You agree not to use the Platform to collect, upload, transmit, display, or distribute any User Content</p>
                <ol className="list-disc pl-4"><li>    that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</li>
                    <li>   that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable;</li>
                    <li>  that is harmful to minors in any way; or</li>
                    <li>  that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</li></ol>
                <p> </p>
                <p>(b) In addition, you agree not to:</p>
                <ol className="list-disc pl-4"><li>   upload, transmit, or distribute to or through the Platform any computer viruses, worms, or any software intended to damage or alter a computer system or data;</li>
                    <li>   send through the Platform unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise;</li>
                    <li>  use the Platform to harvest, collect, gather or assemble information or data regarding other users, including email addresses, without their consent;</li>
                    <li>   interfere with, disrupt, or create an undue burden on servers or networks connected to the Platform, or violate the regulations, policies or procedures of such networks;</li>
                    <li>   attempt to gain unauthorized access to the Platform (or to other computer systems or networks connected to or used together with the Platform), whether through password mining or any other means;</li>
                    <li>   harass or interfere with any other user’s use and enjoyment of the Platform; or</li>
                    <li>  use software or automated agents or scripts to produce multiple accounts on the Platform, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Platform.</li></ol>
                <p> </p>
                <p>(c) In addition, you agree not to disparage any other user of the Platform to any third party based on interactions through the Platform.</p>
                <p> </p>
                <p><strong>Enforcement. </strong>We reserve the right (but have no obligation except in rare circumstances) to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 8, and/or reporting you to law enforcement authorities.</p>
                <p> </p>
                <p><strong>Feedback. </strong>If you provide Company with any feedback or suggestions regarding the Platform (“Feedback”), you hereby assign to Company all rights in such Feedback and agree that Company shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate. Company will treat any Feedback you provide to Company as non-confidential and non-proprietary. You agree that you will not submit to the Company any information or ideas that you consider to be confidential or proprietary.</p>
                <p> </p>
                <p><strong>No Investment Advice; Not an Investment Advisor; Not a Broker-Dealer. </strong> <p></p>

                    The Company is not an investment advisory service, nor is it a registered investment advisor or broker-dealer and does not purport to tell or suggest the value of any securities or which securities customers should buy or sell for themselves. The users of the Platform may hold positions in the stocks or industries discussed here. You understand and acknowledge that there is a very high degree of risk involved in trading securities. The Company, the users, the authors, the publisher, and all affiliates of the Platform assume no responsibility or liability for your trading and investment results. Factual statements or publications on the Platform are made as of the date stated and are subject to change without notice. It should not be assumed that the methods, techniques, or indicators presented in these products will be profitable or that they will not result in losses. Past results of any individual trader or trading system published on the Platform are not indicative of future returns by that trader or system, and are not indicative of future returns which will be realized by you. In addition, the indicators, strategies, columns, articles and all other features of the Platform are provided for informational and educational purposes only and should not be construed as investment advice. Examples presented on the Platform are for educational purposes only. Such set-ups are not solicitations of any order to buy or sell. Accordingly, you should not rely solely on the Information in making any investment. You should always check with your licensed financial advisor and tax advisor to determine the suitability of any investment.</p>
                <p> </p>
                <p>Content, news, research, tools, market data and securities symbols are for educational and</p>
                <p>illustration purposes only and are not substitutes for professional advice. All information</p>
                <p>provided is provided in good faith. We make no representation or warranty of any</p>
                <p>kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or</p>
                <p>completeness of any information on the website, mobile application or associated social media pages.</p>
                <p> </p>
                <p><strong>Live Trading Risks. </strong>By using the Platform, you acknowledge and agree that live algorithmic trading has various risks and the Company shall not have any liability for any losses incurred through using the Platform, such risks included, without limitation:</p>
                <p> </p>
                <ol className="list-disc pl-4"><li>  Design risks; errors in data, incorrect assumptions, logical program errors. Even if you designed a perfect strategy, past performance is no guarantee of future results;</li>
                    <li>  Loss of internet connectivity; resulting in disconnections from brokerage, trades not placed, not seeing the results of algorithm, orders failing to be placed;</li>
                    <li>  Poor design of algorithm; resulting in runaway strategies, machine gun orders, run-time errors halting program operation, failure to trade when expected, termination of the algorithm;</li>
                    <li>  Unexpected market conditions; resulting in poor fill prices, rapid losses (flash crashes), late exchange openings;</li>
                    <li>  Data issues; broken, dirty, delayed or intermittent data connections causing algorithm errors. This includes data inaccuracies (exchanges sometimes publish bad/fictional trades);</li>
                    <li>  Execution issues; orders submissions, updates and cancellations could be rejected or delayed. Trades could be disallowed by authorities without warning;</li>
                    <li>  Malicious activities; criminal activity could cause your algorithm to fail or your brokerage account to become compromised, leaking personal information, intellectual property or theft of your funds; or</li>
                    <li>  These events can cause the loss of all funds and holdings in your brokerage account. Algorithmic trading losses can occur faster than in manual trading and you should consult an investment professional to discuss these risks. You should continually monitor the operation of a live trading algorithm to ensure it is running properly.</li></ol>
                <p> </p>
                <p><strong>EVERY TIME YOU USE THE PLATFORM YOU AGREE THAT IN ALL CASES THE COMPANY BEARS NO RESPONSIBILITY FOR LOSSES INCURRED, AND OFFERS NO GUARANTEES OR EXPECTATIONS ON YOUR ALGORITHM PERFORMANCE OR STABILITY.</strong></p>
                <p> </p>
                <p><strong>4. INDEMNIFICATION</strong></p>
                <p> </p>
                <p>You agree to indemnify and hold Company (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of</p>
                <ol className="list-disc pl-4"><li>  your use of the Platform;</li>
                    <li>  your violation of these Terms;</li>
                    <li>  your violation of applicable laws or regulations; or</li>
                    <li>  your User Content. Company reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of the Company. Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</li></ol>
                <p> </p>
                <p><strong>5. THIRD PARTIES</strong></p>
                <p> </p>
                <p><strong>Third Party Links </strong>The Platform may contain links to third-party websites and services (“Third Party Links”). Such Third Party Links are not under the control of the Company, and Company is not responsible for any Third Party Links. The Company provides access to these Third Party Links only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Links. You use all Third Party Links at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third Party Links, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Links.</p>
                <p> </p>
                <p><strong>Other Users. </strong>Each Platform user is solely responsible for any and all of its own User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. We make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content. Your interactions with other Platform users are solely between you and such users. You agree that the Company will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Platform user, we are under no obligation to become involved.</p>
                <p> </p>
                <p><strong>Release. </strong>You hereby release and forever discharge the Company (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Platform (including any interactions with, or act or omission of, other Platform users or any Third Party Links). .</p>
                <p> </p>
                <p><strong>6. DISCLAIMERS</strong></p>
                <p> </p>
                <p>THE PLATFORM IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE Platform, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.</p>
                <p> </p>
                <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
                <p> </p>
                <p>THE COMPANY PROVIDES THE CONTENT OF THE PLATFORM FOR INFORMATIONAL, EDUCATIONAL AND NONCOMMERCIAL PURPOSES ONLY. SINCE EACH INDIVIDUAL'S SITUATION IS UNIQUE, A QUALIFIED PROFESSIONAL SHOULD BE CONSULTED BEFORE MAKING FINANCIAL DECISIONS. ALTHOUGH WE MAY PROVIDE DATA, INFORMATION AND CONTENT RELATING TO INVESTMENT APPROACHES AND OPPORTUNITIES TO BUY OR SELL SECURITIES, INCLUDING MUTUAL FUNDS AND EXCHANGE-TRADED FUNDS, YOU SHOULD NOT CONSTRUE ANY SUCH INFORMATION AS INVESTMENT, FINANCIAL, TAX, LEGAL OR OTHER ADVICE. YOU ALONE WILL BEAR THE SOLE RESPONSIBILITY OF EVALUATING THE MERITS AND RISKS ASSOCIATED WITH THE USE OF ANY DATA, INFORMATION OR CONTENT ON THE PLATFORM BEFORE MAKING ANY DECISIONS BASED ON SUCH DATA, INFORMATION OR CONTENT. IN EXCHANGE FOR USING SUCH DATA, INFORMATION OR CONTENT, YOU AGREE NOT TO HOLD THE COMPANY OR ITS THIRD-PARTY CONTENT PROVIDERS LIABLE FOR ANY POSSIBLE CLAIM FOR DAMAGES ARISING FROM ANY DECISION YOU MAKE BASED ON INFORMATION MADE AVAILABLE TO YOU THROUGH THE PLATFORM.</p>
                <p> </p>
                <p>WE DO NOT PROVIDE TAX, ACCOUNTING, LEGAL, INVESTMENT, OR FINANCIAL SERVICES. THE INFORMATION AVAILABLE THROUGH THE PLATFORM IS PROVIDED BY THIRD PARTIES AND SOLELY FOR INFORMATIONAL PURPOSES ON AN “AS IS” BASIS AT YOUR SOLE RISK. THE INFORMATION IS NOT MEANT TO BE, AND SHOULD NOT BE CONSTRUED AS ADVICE OR USED FOR INVESTMENT, LEGAL, ACCOUNTING, OR TAX PURPOSES. THE COMPANY MAKES NO GUARANTEES AS TO THE ACCURATENESS, QUALITY, OR COMPLETENESS OF THE INFORMATION AND QUANTCONNECT SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY ERRORS, OMISSIONS, INACCURACIES IN THE INFORMATION OR FOR ANY USER’S RELIANCE ON THE INFORMATION. YOU ARE SOLELY AND COMPLETELY RESPONSIBLE FOR VERIFYING THE INFORMATION AS BEING APPROPRIATE FOR YOUR PERSONAL USE, INCLUDING WITHOUT LIMITATION, SEEKING THE ADVICE OF A QUALIFIED PROFESSIONAL REGARDING ANY SPECIFIC FINANCIAL, LEGAL, ACCOUNTING, OR TAX QUESTIONS A USER MAY HAVE. THE COMPANY DOES NOT ENDORSE ANY PARTICULAR FINANCIAL, LEGAL, ACCOUNTING, OR TAX PROFESSIONALS PROVIDING CONTENT ON THE PLATFORM, AND IS NOT RESPONSIBLE FOR ANY CLAIMS MADE BY ANY FINANCIAL, LEGAL, ACCOUNTING OR TAX PROFESSIONALS. THE COMPANY IS NOT ENDORSED BY OR AFFILIATED WITH ANY STATE BAR ASSOCIATION OR OTHER LEGAL OR ACCOUNTING MEMBERSHIP ORGANIZATION OR ASSOCIATION, TAX AUTHORITIES, OR AGENCIES OR ASSOCIATIONS, OR FINRA OR ANY OTHER FINANCIAL REGULATORY AUTHORITY, AGENCY, OR ASSOCIATION.</p>
                <p> </p>
                <p> </p>
                <p><strong>7. LIMITATION OF LIABILITY</strong></p>
                <p> </p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE Platform, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE PLATFORM IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.</p>
                <p> </p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT.</p>
                <p> </p>
                <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
                <p> </p>
                <p><strong>8. TERMS & TERMINATION</strong></p>
                <p> </p>
                <p>Subject to this Section, these Terms will remain in full force and effect while you use the Platform. We may suspend or terminate your rights to use the Platform (including your Account) at any time for any reason at our sole discretion, including for any use of the Platform in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the Platform will terminate immediately. Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through 2.5, Section 3 and Sections 4 through 10.</p>
                <p> </p>
                <p><strong>9. COPYRIGHT POLICY</strong></p>
                <p> </p>
                <p>Company respects the intellectual property of others and asks that users of our Platform do the same. In connection with our Platform, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online Platform who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Platform, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification must be provided to us:</p>
                <p> </p>
                <ol className="list-disc pl-4"><li>  Your physical or electronic signature;</li>
                    <li>  Identification of the copyrighted work(s) that you claim to have been infringed;</li>
                    <li>  Identification of the material on our services that you claim is infringing and that you request us to remove;</li>
                    <li>  Sufficient information to permit us to locate such material;</li>
                    <li>  Your address, telephone number, and e-mail address;</li>
                    <li>  A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>
                    <li>  A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li></ol>
                <p> </p>
                <p>Please note that, any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.</p>
                <p> </p>
                <p><strong>10. GENERAL</strong></p>
                <p> </p>
                <p><strong>Changes. </strong>These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Platform. You are responsible for providing us with your most current email address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Any changes to these Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch of an email notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Platform. These changes will be effective immediately for new users of our Platform. Continued use of our Platform following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
                <p> </p>
                <p><strong>Dispute Resolution. [PN ROSE]</strong></p>
                <p> </p>
                <p><strong>Export. </strong>The Platform may be subject to Malaysia export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any Malaysia technical data acquired from Company, or any products utilizing such data, in violation of the Malaysia export laws or regulations.</p>
                <p> </p>
                <p><strong>Disclosures. </strong>Company is located at the address in Section 10.9. <strong>[PN ROSE]</strong></p>
                <p> </p>
                <p><strong>Electronic Communications. </strong>The communications between you and Company use electronic means, whether you use the Platform or send us emails, or whether Company posts notices on the Platform or communicates with you via email. For contractual purposes, you</p>
                <ol><li>consent to receive communications from Company in an electronic form; and</li>
                    <li>agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in a hardcopy writing. The foregoing does not affect your non-waivable rights.</li></ol>
                <p> </p>
                <p><strong>Entire Terms. </strong>These Terms constitute the entire agreement between you and us regarding the use of the Platform. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The Company may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.</p>
                <p><strong> </strong></p>
                <p><strong>Copyright/Trademark Information. </strong>Copyright © 2021 Axehedge Sdn. Bhd. All rights reserved. All trademarks, logos and service marks ("Marks") displayed on the Platform are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third parties which may own the Marks.</p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p><strong>11. CONTACT INFORMATION</strong></p>
                <p> </p>
                <p>We welcome your comments or questions about this privacy policy. You may also contact us at our address:</p>
                <p> </p>
                <p>Axehedge</p>
                <p>7B, Jalan Tun Mohd Fuad 3,</p>
                <p>Taman Tun Dr. Ismail,</p>
                <p>Kuala Lumpur, Malaysia, 60000</p>
                <p>Telephone: (+60) 377 285 188</p>
                <p>Email: hello@axehedge.com</p>

            </div>
            <div className="bg-action-blue lg:px-36 divide-y-2 divide-light-color overflow-hidden">
                <EmailSection />
                <SocialSection />
                <div className=" py-6 text-light-grey text-xs md:text-base font-thin px-4 flex flex-row justify-between">
                    <p className='text-white hidden md:block'>Copyright © 2022 AxeHedge. All rights reserved</p>

                    <div className="flex flex-row gap-x-1">
                        <p className="links-footer pr-4" onClick={() => { history.push('/faqs') }}>FAQs</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/general-disclosure') }}>General Disclousre</p>
                        <p className="links-footer pr-4" onClick={() => { history.push('/terms-and-conditions') }}>Terms & Conditions</p>
                        <p className="links-footer" onClick={() => { history.push('/privacy-policy') }}>Privacy Policy</p>
                    </div>
                </div>
                <div>
                    <p className='text-white block md:hidden m-3'>Copyright © 2022 AxeHedge. All rights reserved</p>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions
