import { useHistory } from "react-router";
import EmailSection from "./EmailSection";
import SocialSection from "./SocialsSection";

const Footer = () => {
  const history = useHistory();
  return (
    <div className="overflow-hidden bg-white lg:px-36">

      <SocialSection />
      <EmailSection />
      <div className="flex flex-row justify-between px-4 py-6 text-xs font-normal text-thin-grey md:text-base">
        <p>© Copyright 2021 AXEHEDGE All Rights Reserved.</p>
        <div className="flex flex-row gap-x-1">
          <p
            className="pr-4 cursor-pointer"
            onClick={() => {
              history.push("/privacy-policy");
            }}
          >
            Privacy Policy
          </p>
          <p
            className="pr-4 links-footer-section"
            onClick={() => {
              history.push("/terms-and-conditions");
            }}
          >
            Terms & Conditions
          </p>
          <p
            className="links-footer-section"
            onClick={() => {
              history.push("/faqs");
            }}
          >
            FAQ
          </p>


        </div>
      </div>
    </div>
  );
};

export default Footer;
